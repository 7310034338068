import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { PlusOutlined} from "@ant-design/icons";
import { Button, Space, notification, message,Dropdown, Table } from "antd";
import useReconocimientos from "./hooks/useReconocimientos";
import useModalCrearReconocimiento from "./hooks/useModalCrearReconocimiento";
import { ModalCrearReconocimiento } from "./components";

const ReconocimientoPage = () => {

    return (
        <JotaiProvider>
            <Container titulo="Bandeja de Reconocimientos" botonExtra={<Botones/>}>
            <ListaReconocimientos/>
            <ModalCrearReconocimiento />
            </Container>
        </JotaiProvider>

    );
}

const Botones = () => {
    const { setOpen: setOpenModalCrearReconocimiento } = useModalCrearReconocimiento();
    return (
        <Button key="crear-reconocimiento" type="primary" icon={<PlusOutlined />}  onClick={() => setOpenModalCrearReconocimiento(true)}>
          Crear Reconocimiento
        </Button>
      );
}
const ListaReconocimientos = () => {

    const { loading, reconocimientos, fetchReconocimientos } = useReconocimientos();

    useEffect(() => {
        const inicializar = () => {
            // if (open) {
                fetchReconocimientos();
                // setFormInstance(form);
            // }
        }
        inicializar();
    }, []);

    //const { setOpen: setOpenModalCrearProveedor } = useModalCrearProveedor();

    const columnas = [
        {
            title: 'Titulo',
            dataIndex: 'titulo',
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
        },
        // {
        //     title: 'Acciones',
        //     align: 'center',
        //     width: '10%',
        //     render: (fila) => <MenuAcciones requisito={fila} />
        // },
    ];

    return (
        <Table
            rowKey={(reconocimientos) => reconocimientos.id}
            dataSource={reconocimientos}
            columns={columnas}
        />
    )
}
export default ReconocimientoPage;