import { useAtom } from "jotai";
import { openModalCrearTurnoAtom
 } from "../store/store";
import { useState } from "react";
import { TurnoService } from "services/asistencias/TurnoService";
// import { useNotificacion } from "@/stores/global";
import useTurnos from "./useTurnos";
import { Button, Col, Input, Modal, Row, Space, Form, Typography, notification } from "antd"
import { LoginService } from "services/LoginService";
import { TipoTurno } from "enums/asistencia/TipoTurno";
import { useHistory } from "react-router-dom";

const useModalCrearTurno = () => {
    const history = useHistory();
    const [open, setOpen] = useAtom(openModalCrearTurnoAtom);
    // const [, setTurnoCreado] = useAtom(turnoCreadoAtom);
    const { fetchTurnos } = useTurnos();

    const crearTurno = async (datosFormulario) => {
        
        let usuarioRegistro = {
            nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
            numeroDocumentoIdentidad:
              LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
          };
        datosFormulario.usuarioRegistro = usuarioRegistro;
        
        const respuesta = await TurnoService.insertar({ ...datosFormulario });
        // setTurnoCreado(respuesta);
        // console.log("respuesta::::", respuesta);

        notification.success({
            message: "Turno creado",
            description: "El turno ahora se encuentra disponible para su uso."
        })
        //fetchTurnos();
       // console.log("datosFormulario.tipo0::::", datosFormulario.tipo);
        setOpen(false);
        console.log("datosFormulario.tipo::::", datosFormulario.tipo);
        if(datosFormulario.tipo == TipoTurno.SEMANAL){
            history.push(`/asistencia/turno/configuracion-semanal/${respuesta.id}/crear`);
        }else if(datosFormulario.tipo == TipoTurno.MENSUAL){
            history.push(`/asistencia/turno/configuracion-mensual/${respuesta.id}/crear`);
        }else if(datosFormulario.tipo == TipoTurno.ROTATIVOS){
            history.push(`/asistencia/turno/configuracion-rotativo/${respuesta.id}/crear`);
        }
        
        return true;
    }

    return {
        open,
        setOpen,
        crearTurno
    };


}

export default useModalCrearTurno;