import React from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import { Form, Input, Row, Col, Button, Space, notification, Select } from "antd";
import { FormEditarPublicacion } from "./componentes/form-editar-publicacion";

const EditarPublicacionPage=()=>{
  let history = useHistory();

  const onClickRetonarListarTipoDocumentos=()=>{
    history.push("/comunicaciones-cultura/publicaciones");
  }

  return(
    <div style={{ margin: "0px 20%" }}>
    <Container titulo="Editar Publicación" onClickRetornar={onClickRetonarListarTipoDocumentos}>
       <FormEditarPublicacion/>
    </Container>
    </div>
    
  )

}
export default EditarPublicacionPage;