import { atom } from "jotai";

export const firmantesAtom = atom([]);
export const firmanteSeleccionadoAtom = atom(undefined);
export const zoomAtom = atom(1.25);
export const totalPaginasAtom = atom(0);
export const urlDocumentoAtom = atom(undefined);
export const componentesAtom = atom([]);
export const componentesSeleccionadosAtom = atom([]);

//eliminar
export const testAtom = atom(undefined);