import React, { useState } from "react";
import {
  Input,
  Button,
  Checkbox,
  TimePicker,
  InputNumber,
  Table,
  Layout,
  Form,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import { Provider as JotaiProvider } from "jotai";
import { useHistory } from "react-router-dom";
import { Container } from "components/Container";

const { Header, Content } = Layout;

const ConfiguracionRotativoCrearPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [cycleDays, setCycleDays] = useState(0);
  const [shiftData, setShiftData] = useState([]);

  const columns = [
    {
      title: "Día",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Trabaja",
      dataIndex: "works",
      key: "works",
      render: (_, record) => (
        <Checkbox
          checked={record.works}
          onChange={(e) =>
            updateShiftData(record.key, "works", e.target.checked)
          }
        />
      ),
    },
    {
      title: "Desde",
      dataIndex: "from",
      key: "from",
      render: (_, record) => (
        <TimePicker
          value={record.from ? moment(record.from, "HH:mm") : null}
          onChange={(time) =>
            updateShiftData(
              record.key,
              "from",
              time ? time.format("HH:mm") : ""
            )
          }
          format="HH:mm"
          disabled={!record.works}
        />
      ),
    },
    {
      title: "Hasta",
      dataIndex: "to",
      key: "to",
      render: (_, record) => (
        <TimePicker
          value={record.to ? moment(record.to, "HH:mm") : null}
          onChange={(time) =>
            updateShiftData(record.key, "to", time ? time.format("HH:mm") : "")
          }
          format="HH:mm"
          disabled={!record.works}
        />
      ),
    },
    {
      title: "Cant. Horas",
      dataIndex: "hours",
      key: "hours",
      render: (_, record) => (
        <InputNumber
          value={record.hours}
          onChange={(value) => updateShiftData(record.key, "hours", value)}
          disabled={!record.works}
          min={0}
        />
      ),
    },
  ];

  const updateShiftData = (key, field, value) => {
    const updated = shiftData.map((item) =>
      item.key === key ? { ...item, [field]: value } : item
    );
    setShiftData(updated);
  };

  const handleCycleDaysChange = (value) => {
    const updatedData = Array.from({ length: value }, (_, index) => ({
      key: String(index + 1),
      day: String(index + 1),
      works: index < shiftData.length ? shiftData[index].works : false,
      from: index < shiftData.length ? shiftData[index].from : "",
      to: index < shiftData.length ? shiftData[index].to : "",
      hours: index < shiftData.length ? shiftData[index].hours : 0,
    }));
    setShiftData(updatedData);
    setCycleDays(value);
  };

  const handleClearAll = () => {
    setCycleDays(0);
    setShiftData([]);
    form.resetFields();
  };

  const handleSave = async (values) => {
    const payload = {
      nombreTurno: values.nombreTurno,
      trabajaFestivos: values.trabajaFestivos,
      marcaColacion: values.marcaColacion,
      jornadaExcepcional: values.jornadaExcepcional,
      duracionColacion: values.duracionColacion,
      tolerancia: values.tolerancia,
      ciclo: shiftData,
    };

    try {
      await axios.post("/api/turnos", payload); // Cambiar por la URL real del backend
      message.success("Turno guardado exitosamente");
      history.push("/asistencia/turnos");
    } catch (error) {
      console.error("Error al guardar el turno:", error);
      message.error("Error al guardar el turno. Intente nuevamente.");
    }
  };

  return (
    <JotaiProvider>
      <Container
        titulo={
          <div>
            <h5>Configuración de turno rotativo</h5>
          </div>
        }
        onClickRetornar={() => history.push("/asistencia/turnos")}
        botonExtra={<Botones />}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
          initialValues={{
            trabajaFestivos: false,
            marcaColacion: false,
            jornadaExcepcional: false,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginBottom: "20px",
            }}
          >
            <Form.Item
              name="nombrnombreeTurno"
              label="Nombre"
              rules={[{ required: true, message: "Ingrese un nombre" }]}
              style={{ width: "70%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item label={" "} style={{ width: "20%" }}>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#007bff",
                  fontSize: "14px",
                }}
              >
                Total de horas del turno:{" "}
                {shiftData.reduce(
                  (total, item) => total + (item.works ? item.hours : 0),
                  0
                )}
              </span>
            </Form.Item>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              // marginBottom: "20px",
            }}
          >
            <Form.Item
              label="Cant. días ciclo"
              name="diasCiclo"
              valuePropName="checked"
            >
              <InputNumber
                min={0}
                value={cycleDays}
                onChange={handleCycleDaysChange}
                style={{ marginRight: "20px" }}
              />
            </Form.Item>

            <Form.Item
              label={" "}
              name="trabajaFestivos"
              valuePropName="checked"
              style={{ marginLeft: "41px" }}
            >
              <Checkbox>Trabaja festivos</Checkbox>
            </Form.Item>
            <Form.Item
              label={" "}
              name="marcaRefrigerio"
              valuePropName="checked"
              style={{ marginLeft: "41px" }}
            >
              <Checkbox>Marca refrigerio</Checkbox>
            </Form.Item>

            <Form.Item
              label="Duracion Refrigerio(hrs)"
              name="duracionRefrigerio"
              style={{ marginLeft: "41px" }}
            >
              <TimePicker
                format="HH:mm"
                defaultValue={moment("00:00", "HH:mm")} // Valor por defecto: 0 hora
              />
            </Form.Item>
            <Form.Item
              label="Tolerancia(hrs)"
              name="tolerancia"
              style={{ marginLeft: "41px" }}
            >
              <TimePicker
                format="HH:mm"
                defaultValue={moment("00:00", "HH:mm")} // Valor por defecto: 0 hora
              />
            </Form.Item>
            <Form.Item
              label={" "}
              name="jornadaExcepcional"
              valuePropName="checked"
              style={{ marginLeft: "41px" }}
            >
              <Checkbox>Jornada Excepcional</Checkbox>
            </Form.Item>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          > */}
            
          {/* </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginBottom: "10px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Distribución</span>
            <Button onClick={handleClearAll}>Limpiar todo</Button>
          </div>

          <Table columns={columns} dataSource={shiftData} pagination={false} />
        </Form>
      </Container>
    </JotaiProvider>
  );
};

const Botones = () => {
  return (
    <Button type="primary" htmlType="submit" style={{ marginTop: "20px" }}>
      Guardar
    </Button>
  );
};
export default ConfiguracionRotativoCrearPage;
