import React, { useEffect } from 'react';
import { Button, Col, DatePicker, Empty, Form, Row, Tooltip } from 'antd';
import { useCalendario } from '../hooks';
import { CalendarOutlined } from "@ant-design/icons"
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Provider as JotaiProvider } from "jotai";

const TabCalendario = () => {
    return (
        <JotaiProvider>
            <Filtros />
            <Calendario />
        </JotaiProvider>
    );
};

const Calendario = () => {
    const { solicitudes } = useCalendario();

    if (solicitudes.length === 0) {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No existe datos. Haga clic en Mostrar Calendario" />
        )
    }

    return (
        // <InfiniteScroll
        //     dataLength={colaboradores.length}
        //     next={loadMoreColaboradores}
        //     hasMore={colaboradores.length < totalColaboradores}
        //     loader={<h4>Loading...</h4>}
        //     height="calc(100vh - 270px)"
        // >
        //     <Cabecera />
        //     {
        //         colaboradores.map(
        //             (colaborador) =>
        //                 <Fila key={colaborador.colaborador.id} colaborador={colaborador} />)
        //     }
        // </InfiniteScroll>
        <div style={{ height: "calc(100vh - 270px)", overflow: "auto" }}>
            <Cabecera />
            {
                solicitudes.map(
                    (solicitud) =>
                        <Fila key={solicitud.colaborador.id} solicitud={solicitud} />)
            }
        </div>
    )
}

const Fila = ({ solicitud }) => {
    const { getDias, fechaSeleccionada } = useCalendario();

    return (
        <div style={style.fila.container}>
            <div style={style.fila.celdaColaborador}>
                {solicitud.colaborador.nombreCompleto}
            </div>
            <div style={{ width: "75%" }}>
                <div style={style.fila.containerDias}>
                    {solicitud?.vacaciones &&
                        solicitud.vacaciones?.map((solicitud) => (
                            <Tooltip key={solicitud.id} title={solicitud.tipo.nombre} placement="top">
                                <div
                                    style={{
                                        ...style.fila.celdaDias,
                                        left: `calc( (${solicitud.diaInicio} - 1 )  * (100% / ${fechaSeleccionada.daysInMonth()}))`,
                                        width: `calc(${solicitud.diasSolicitados} * (100% / ${fechaSeleccionada.daysInMonth()}))`,
                                        backgroundColor: solicitud.color ? solicitud.color : "lightblue"
                                    }}
                                >

                                </div>
                            </Tooltip>
                        ))}

                    {getDias().map((day) => (
                        <div key={day} style={style.dias} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const Cabecera = () => {
    const { getDias, fechaSeleccionada } = useCalendario();

    return (
        <div style={style.cabecera.container}>
            <div style={style.cabecera.celdaColaborador}>
                Nombres y Apellidos
            </div>
            <div style={{ width: "75%" }}>
                <div style={style.cabecera.containerDias}>
                    {getDias().map((day) => (
                        <div key={day} style={{
                            ...style.dias,
                            flexDirection: "column"
                        }}>
                            <span>
                                {moment({
                                    year: fechaSeleccionada.year(),
                                    month: fechaSeleccionada.month(),
                                    date: day,
                                })
                                    .format("dd")
                                    .toUpperCase()}
                            </span>
                            <span>{day}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Filtros = () => {

    const [form] = Form.useForm();
    const { fechaSeleccionada, setFormInstance, onClickBtnMostrarCalendario } = useCalendario();

    useEffect(() => {
        setFormInstance(form);
    }, []);

    return (
        <Form form={form}>
            <Row gutter={24}>
                <Col span="8">
                    <Form.Item
                        name="periodo"
                        label="Mes"
                        rules={[
                            {
                                required: true,
                                message: 'Mes es obligatorio',
                            },
                        ]}
                        initialValue={fechaSeleccionada}
                    >
                        <DatePicker
                            allowClear={false}
                            picker="month"
                            format="MMMM - YYYY"
                            placeholder="Fecha"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col span="8">
                    <Button type='primary' onClick={onClickBtnMostrarCalendario}>
                        <CalendarOutlined /> Mostrar Calendario
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const style = {
    dias: {
        flex: "1",
        minWidth: "0",
        borderRight: "1px solid #ccc",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "10px",
    },
    cabecera: {
        container: {
            backgroundColor: "white",
            height: "35px",
            display: "flex",
            position: "sticky",
            top: 0,
            zIndex: 10
        },
        containerDias: {
            display: "flex",
            position: "relative",
            overflowX: "auto",
            border: "1px solid #ccc",
            borderRight: "0px",
            height: "100%",
        },
        celdaColaborador: {
            width: "25%",
            border: "1px solid #ccc",
            borderRight: "0px"
        }
    },
    fila: {
        container: {
            height: "35px",
            display: "flex"
        },
        containerDias: {
            display: "flex",
            position: "relative",
            overflowX: "auto",
            border: "1px solid #ccc",
            borderRight: "0px",
            height: "100%",
            borderTop: "0px"
        },
        celdaColaborador: {
            width: "25%",
            border: "1px solid #ccc",
            borderRight: "0px",
            borderTop: "0px"
        },
        celdaDias: {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            height: "50%",
            zIndex: "1",
            borderRadius: "15px",
        }
    }
}

export default TabCalendario;