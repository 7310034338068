import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { reconocimientosAtom } from "../store/store";
import { ReconocimientoService } from "services/comunicacion-cultura/ReconocimientoService";

const useReconocimientos = () => {

    const [reconocimientos, setReconocimientos] = useAtom(reconocimientosAtom);
    const [loading, setLoading] = useState(true);

    const fetchReconocimientos = async () => {
        setLoading(true);
        const response = await ReconocimientoService.listar();
        setReconocimientos(response);
        setLoading(false);
    }

    return {
        loading,
        reconocimientos,
        fetchReconocimientos
    };
}

export default useReconocimientos;
