import React from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import { Form, Input, Row, Col, Button, Space, notification, Select } from "antd";
import { FormCrearPublicacion } from "./componentes/form-crear-publicacion";

const CrearPublicacionPage=()=>{
  let history = useHistory();

  const onClickRetonarListarTipoDocumentos=()=>{
    history.push("/comunicaciones-cultura/publicaciones");
  }

  return(
    <div style={{ margin: "0px 20%" }}>
    <Container titulo="Crear Publicación" onClickRetornar={onClickRetonarListarTipoDocumentos}>
       <FormCrearPublicacion/>
    </Container>
    </div>
    
  )

}
export default CrearPublicacionPage;