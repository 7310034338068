import { atom } from "jotai";

export const tiposAbsentismoAtom = atom([]);
export const tipoAbsentismoSeleccionadoAtom = atom(undefined);
export const reglaSeleccionadaAtom = atom(undefined);
export const tipoAusenciaIdAtom = atom(undefined);

export const openDrawerConfigurarReglaAtom = atom(false);
export const openDrawerConfigurarDetalleReglaAtom = atom(false);
export const openDrawerConfigurarColorAtom = atom(false);
export const colorAusenciaAtom = atom("#ccc");