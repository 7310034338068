import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.BASE;

const ReconocimientoService = {
  async insertar(reconocimiento) {
    const respuesta = await ApiService(URL_API).post("/comunicaciones-cultura/reconocimiento", reconocimiento);
    return respuesta.data;
  },
  // async actualizar(publicacion) {
  //   const respuesta = await ApiService(URL_API).put("/comunicaciones-cultura/publicaciones", publicacion);
  //   return respuesta.data;
  // },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/comunicaciones-cultura/reconocimientos");
    return respuesta.data;
  },
  // async eliminar(id) {
  //   const respuesta = await ApiService(URL_API).delete(`/comunicaciones-cultura/publicacion/${id}`);
  //   return respuesta.data;
  // },
  // async buscarPorId(id) {
	// 	const respuesta = await ApiService(URL_API).get(`/comunicaciones-cultura/publicacion/${id}`);
	// 	return respuesta.data;
	// },
  // async cargarColaboradorExcel(formData) {
  //   const respuesta = await ApiService(URL_API).post("/comunicaciones-cultura/publicacion/cargarColaboradorExcel", formData);
  //   return respuesta.data;
  // },
};

export { ReconocimientoService };
