import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.ASISTENCIA;

const AsignacionService = {
  async listarColaboradoresToAsignacionFiltro(params) {
		const respuesta = await ApiService(URL_API).get(`/asignacion/buscarColaboradoresFiltro`, {
		params: params,
		});
		return respuesta.data;
	},
};

export { AsignacionService };
