import { useAtom } from "jotai";
import { openModalCrearReconocimientoAtom } from "../store/store";
import { useState } from "react";
import { ReconocimientoService } from "services/comunicacion-cultura/ReconocimientoService";
// import { useNotificacion } from "@/stores/global";
import useReconocimientos from "./useReconocimientos";
import { Button, Col, Input, Modal, Row, Space, Form, Typography, notification } from "antd"
import { LoginService } from "services/LoginService";

const useModalCrearReconocimiento = () => {

    // const notificacion = useNotificacion((state) => state.notificacion);

    const [open, setOpen] = useAtom(openModalCrearReconocimientoAtom);
    const { fetchReconocimientos } = useReconocimientos();

    const crearReconocimiento = async (datosFormulario) => {
        
        let usuarioRegistro = {
            nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
            numeroDocumentoIdentidad:
              LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
          };
        datosFormulario.usuarioRegistro = usuarioRegistro;
        console.log("crearReconocimiento::::", datosFormulario);
        await ReconocimientoService.insertar({ ...datosFormulario });
        notification.success({
            message: "Reconocimiento creado",
            description: "El reconocimiento ahora se encuentra disponible para su uso."
        })
        fetchReconocimientos();
        setOpen(false);
        return true;
    }

    return {
        open,
        setOpen,
        crearReconocimiento
    };


}

export default useModalCrearReconocimiento;