import React, { useEffect } from 'react';
import { Alert, Button, Drawer } from 'antd';
import { useDrawerConfigurarColor } from '../hooks';
import { TwitterPicker } from 'react-color';


const DrawerConfigurarColor = () => {

    const {
        open,
        color,
        mostrarMensaje,
        setMostrarMensaje,
        actualizarColor,
        onChangeColor,
        cerrarModal
    } = useDrawerConfigurarColor();

    useEffect(() => {
        if (mostrarMensaje) {
            const timer = setTimeout(() => {
                setMostrarMensaje(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [mostrarMensaje]);


    return (
        <Drawer title="Selecciona tu color" placement="right" onClose={cerrarModal} open={open}>
            <div style={{ height: "18px", backgroundColor: color, marginBottom: "12px", borderRadius: "10px" }}>

            </div>

            <TwitterPicker color={color} onChangeComplete={onChangeColor} />

            <Button type='primary' onClick={actualizarColor} style={{ marginTop: "10px" }}>
                Guardar
            </Button>

            {mostrarMensaje
                && <Alert message="Color actualizado!" type="success" showIcon style={{ marginTop: "10px" }} />
            }

        </Drawer>
    );
};


export default DrawerConfigurarColor;