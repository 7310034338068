import { useAtom } from "jotai";
import { coloresAsignadosAtom } from "../store/Store";

const colorOptions = [
    '#0693E3',
    '#9900EF',
    '#00D084',
    '#FCB900',
    '#607D8B'
];

const useDragAndDropFirmantes = () => {

    const [coloresAsignados, setColoresAsignados] = useAtom(coloresAsignadosAtom);

    const onDragEnd = (result, move) => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex !== destinationIndex) {
            move(sourceIndex, destinationIndex);
            setColoresAsignados((prevColors) => {
                const updatedColors = [...prevColors];
                const [movedColor] = updatedColors.splice(sourceIndex, 1);
                updatedColors.splice(destinationIndex, 0, movedColor);
                return updatedColors;
            });
        }
    };

    const getRandomColor = () => {
        const availableColors = colorOptions.filter(color => !coloresAsignados.includes(color));
        if (availableColors.length === 0) return null; // No more colors available
        const randomIndex = Math.floor(Math.random() * availableColors.length);
        return availableColors[randomIndex];
    };

    const adicionarFirmante = (add) => {
        const newColor = getRandomColor();
        if (newColor) {
            setColoresAsignados([...coloresAsignados, newColor]);
            add({ color: newColor });
        } else {
            console.warn("No more colors available!");
        }
    };

    const removerFirmante = (remove, index, name) => {
        setColoresAsignados(coloresAsignados.filter((_, idx) => idx !== index));
        remove(name);
    };


    return {
        coloresAsignados,
        getRandomColor,
        onDragEnd,
        adicionarFirmante,
        removerFirmante
    }

}

export default useDragAndDropFirmantes;