import { useAtom } from "jotai";
import { colorAusenciaAtom, openDrawerConfigurarColorAtom, tipoAusenciaIdAtom } from "../ListarTipoAbsentismoStore";
import { useEffect, useState } from "react";
import TipoAbsentismoService from "services/TipoAbsentismoService";

const useDrawerConfigurarColor = () => {
    const [tipoAusenciaId, setTipoAusenciaId] = useAtom(tipoAusenciaIdAtom);
    const [open, setOpen] = useAtom(openDrawerConfigurarColorAtom);
    const [color, setColor] = useState(colorAusenciaAtom);
    const [mostrarMensaje, setMostrarMensaje] = useState(false);

    useEffect(() => {
        const cargarColor = async () => {
            if (open) {
                const response = await TipoAbsentismoService.getColor(tipoAusenciaId);
                setColor(response.color);
            }
        }
        cargarColor();
    }, [open]);

    const abrirModal = async (tipoAusenciaId) => {
        setTipoAusenciaId(tipoAusenciaId);
        setOpen(true);
    }

    const cerrarModal = () => {
        setOpen(false);
    }

    const onChangeColor = (color) => {
        setColor(color.hex);
    }

    const actualizarColor = async () => {
        const response = await TipoAbsentismoService.actualizarColor(tipoAusenciaId, color.replace("#", ''));
        if (response) {
            setMostrarMensaje(true);
        }
    }

    return {
        open,
        color,
        mostrarMensaje,
        setMostrarMensaje,
        onChangeColor,
        actualizarColor,
        abrirModal,
        cerrarModal
    };
};

export default useDrawerConfigurarColor;
