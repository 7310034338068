import React from "react";

import {
    EditOutlined,
    DeleteOutlined,
    MoreOutlined,
    ExclamationCircleOutlined,
    ToolOutlined,
    BgColorsOutlined
} from "@ant-design/icons";

import { Button, Dropdown, Modal, Popconfirm } from "antd";
import { useHistory } from "react-router";
import TipoAbsentismoService from "../../../../services/TipoAbsentismoService";
import { useAtom, useSetAtom } from "jotai";
import { openDrawerConfigurarReglaAtom, tipoAbsentismoSeleccionadoAtom } from "../ListarTipoAbsentismoStore";
import { useDrawerConfigurarColor } from "../hooks";

export const MenuAcciones = ({ tipo, tiposAbsentismo, setTiposAbsentismos }) => {

    const setTipoAbsentimsmoSeleccionado = useSetAtom(tipoAbsentismoSeleccionadoAtom);
    const setOpenDrawerConfigurarRegla = useSetAtom(openDrawerConfigurarReglaAtom);
    const { abrirModal: abrirDrawerConfigurarColor } = useDrawerConfigurarColor();
    let history = useHistory();

    const items = [
        {
            key: 'editar',
            label: 'Editar',
            icon: <EditOutlined />
        },
        {
            key: 'configurar-reglas',
            label: 'Configurar Reglas',
            icon: <ToolOutlined />
        },
        {
            key: 'configurar-color',
            label: 'Configurar Color',
            icon: <BgColorsOutlined />
        },
        {
            type: 'divider'
        },
        {
            key: 'eliminar',
            label: 'Eliminar',
            icon: <DeleteOutlined />,
            danger: true
        }
    ];

    const onClick = ({ key }) => {
        if (key === "editar") {
            editar();
        } else if (key === "eliminar") {
            eliminar();
        } else if (key === "configurar-reglas") {
            configurarReglas();
        } else if (key === "configurar-color") {
            abrirDrawerConfigurarColor(tipo.id);
        }
    };

    const editar = () => {
        const rutaEditarTipoAbsentismoPage = `/tipoAbsentismo/editar/${tipo.id}`;
        history.push(rutaEditarTipoAbsentismoPage);
    }

    const eliminar = async () => {

        Modal.confirm({
            title: '¿Está seguro de eliminar el Tipo Absentismo?',
            icon: <ExclamationCircleOutlined />,
            content: 'Esta acción ya no podrá ser revertida posteriormente.',
            okText: 'Si, Eliminar!',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                await TipoAbsentismoService.eliminar(tipo.id);
                setTiposAbsentismos(tiposAbsentismo.filter((tipoAbsentismo) => tipoAbsentismo.id !== tipo.id));
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const configurarReglas = () => {
        setTipoAbsentimsmoSeleccionado(tipo);
        setOpenDrawerConfigurarRegla(true);
    }

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{ fontSize: "18px", fontWeight: "bold", color: "gray" }} />
            </Button>
        </Dropdown>
    );


}