import { useAtom } from "jotai";
import { formInstanceAtom, loadingAtom } from "../store/Store";
import { FlujoFirmaService } from "services/notificacion";
import { useHistory } from "react-router-dom";

const useCrearFlujoFirma = () => {

    const history = useHistory();

    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [loading, setLoading] = useAtom(loadingAtom);

    const onClickBtnContinuar = () => {
        formInstance.validateFields()
            .then(async (formulario) => {
                setLoading(true);
                const payload = {
                    colaboradorId: formulario.colaborador.value,
                    tipoDocumentoId: formulario.tipoDocumentoId,
                    firmantes: formulario.firmantes.map((firmante) => {
                        return {
                            id: firmante.colaborador.value,
                            color: firmante.color
                        }
                    })
                };

                const formData = new FormData();
                formData.append("documento", formulario.fileDocumento[0].originFileObj);
                formData.append(
                    "formulario",
                    new Blob([JSON.stringify(payload)], {
                        type: "application/json",
                    })
                );

                const response = await FlujoFirmaService.crear(formData);
                history.push(`/notificacion/flujo-firma/${response.id}/configurar`);

            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }


    return {
        formInstance,
        loading,
        onClickBtnContinuar,
        setFormInstance
    };
};

export default useCrearFlujoFirma;