import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { SearchOutlined, ExclamationCircleOutlined, FileDoneOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { Table, Space, Button, Input, Select, notification, Modal, Tag, Popconfirm } from "antd";
import { EnvioService } from "services/EnvioService";
import DetalleEnvioService from "../../../services/DetalleEnvioService";
import { ModalPDF } from "../../../components/PDFViewer";
import Highlighter from "react-highlight-words";
import { Container } from "components/Container";
import { ModalVisorPdf } from "components/common";

const { Option } = Select;

export function DocumentoLaboralPorAprobarListarDetallePage() {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);
	const [detalleEnvio, setDetalleEnvio] = useState([]);
	const { getDatosEnvio } = useContext(SecurityContext);
	const [loading, setLoading] = useState(false);
	const [loadingBotonAprobar, setLoadingBotonAprobar] = useState(false);
	const [filaSeleccionada, setFilaSeleccionada] = useState("");
	const [url, setUrl] = useState("");
	const [visible, setVisible] = useState("");
	const [estadoDetalleEnvio, setEstadoDetalleEnvio] = useState();

	let envioId = getDatosEnvio().id;
	let history = useHistory();

	useEffect(() => {
		async function cargarDatos() {
			//setEstado(-1);
			const detalleEnvio = await DetalleEnvioService.listar(envioId, 6);
			setDetalleEnvio(detalleEnvio);
		}

		cargarDatos();
	}, []);

	var adicionarColumnasFiltro = function getColumns(dataIndex, placeholder, propiedad) {
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={searchInput}
						placeholder={placeholder !== undefined ? placeholder : `Buscar por ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							Buscar
						</Button>
						<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
							Limpiar
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			onFilter: (value, record) =>
				propiedad === undefined
					? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
					: record[dataIndex][propiedad].toString().toLowerCase().includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => searchInput.current.select());
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={propiedad === undefined ? text.toString() : text[propiedad].toString()}
					/>
				) : propiedad === undefined ? (
					text
				) : (
					text[propiedad]
				),
		};
	};

	function handleSearch(selectedKeys, confirm, dataIndex) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	}

	function handleReset(clearFilters) {
		clearFilters();
		setSearchText("");
	}

	async function handleOnSelectVerDetalle(detalleEnvio) {
		try {
			setLoading(true);
			setFilaSeleccionada(detalleEnvio.id);
			setEstadoDetalleEnvio(detalleEnvio.estadoProcesoId);
			const formData = new FormData();
			let data;
			if (detalleEnvio.envio.formatoCargaId === "fa8c5f15-dffb-4c64-8e52-e65de5faea75") {
				formData.append("envioId", detalleEnvio.envio.id);
				formData.append("numeroDocumentoColaborador", detalleEnvio.colaborador.numeroDocumentoIdentidad);
				data = await EnvioService.verPDFExcelOrWs(formData);
			} else {
				formData.append("detalleEnvioId", detalleEnvio.id);
				formData.append("estadoProcesoId", detalleEnvio.estadoProcesoId);
				data = await DetalleEnvioService.verPDF(formData);
				console.log("dataaa???", data)
			}

			const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
			setUrl(url);
			setVisible(true);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	const columnas = [
		{
			title: "Colaborador",
			dataIndex: "colaborador",
			align: "center",
			...adicionarColumnasFiltro("colaborador", "Buscar por Colaborador", "nombreCompleto"),
		},
		{
			title: "Documento",
			dataIndex: "colaborador",
			align: "center",
			...adicionarColumnasFiltro("colaborador", "Buscar por Documento", "numeroDocumentoIdentidad"),
		},
		{
			title: "Email",
			dataIndex: ["colaborador", "correoElectronico"],
			align: "center",
		},

		{
			title: "Estado",
			align: "center",
			render: (fila) => (
				<Tag color="orange" style={{ textTransform: "uppercase" }}>
					{fila.estadoProcesoNombre}
				</Tag>
			),
		},
		{
			title: "Acciones",
			align: "center",
			render: (fila) => (
				<Button type="link" onClick={() => handleOnSelectVerDetalle(fila)} disabled={loading}>
					{loading ? <Loading3QuartersOutlined spin /> : <SearchOutlined />} Ver Documento
				</Button>
			),
		},
	];

	async function handleOnClickAprobar() {
		try {
			setLoadingBotonAprobar(true);
			const datos = {
				id: getDatosEnvio().id,
				organizacionId: getUsuarioAutenticado().organizacionId,
				empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
				usuarioNumeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
				usuarioLogin: getUsuarioAutenticado().login,
				usuarioNombreCompleto: getUsuarioAutenticado().nombreCompleto,
				usuarioCorreoElectronico: getUsuarioAutenticado().correoElectronico,
			};

			await EnvioService.aprobarEnvio(datos);

			notification.success({
				message: "Operación exitosa",
				description: "Se realizo el proceso correctamente.",
			});
			setLoadingBotonAprobar(false);

			history.push("/documento-laboral-porAprobar");
		} catch (error) {
			setLoadingBotonAprobar(false);
		}
	}

	function handleOnclickRechazar() {
		Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			content: "Está seguro de rechazar el documento",
			okText: "Si",
			cancelText: "No",
			onOk() {
				handleOnOkModalRechazar();
			},
		});
	}

	async function handleOnOkModalRechazar() {
		try {
			setLoading(true);
			const datos = {
				envioId: getDatosEnvio().id,
				organizacionId: getUsuarioAutenticado().organizacionId,
				empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
				usuarioNumeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
				usuarioLogin: getUsuarioAutenticado().login,
				usuarioNombreCompleto: getUsuarioAutenticado().nombreCompleto,
				usuarioCorreoElectronico: getUsuarioAutenticado().correoElectronico,
			};

			await EnvioService.rechazarEnvio(datos);

			notification.success({
				message: "Operación exitosa",
				description: "Se realizo el proceso correctamente.",
			});
			setLoading(false);

			history.push("/documento-laboral-porAprobar");
		} catch (error) {
			setLoading(false);
		}
	}

	return (
		<Container
			icono={<FileDoneOutlined style={{ fontSize: "18px" }} />}
			titulo={getDatosEnvio().documentoNombre}
		>
			<Table
				rowKey={(detalleEnvio) => detalleEnvio.id}
				columns={columnas}
				dataSource={detalleEnvio}
				pagination={{
					pageSize: [10],
				}}
			/>
			<div align="center">
				<Space>
					<Popconfirm
						title="¿Está seguro de aprobar los documentos?"
						onConfirm={handleOnClickAprobar}
						// onCancel={cancel}
						okText="Si, aprobar!"
						cancelText="No"
						okButtonProps={{
							loading: loadingBotonAprobar,
						}}
						placement="bottom"
					>
						{/* <Button type="primary" loading={loadingBotonAprobar} onClick={handleOnClickAprobar}> */}
						<Button type="primary" loading={loadingBotonAprobar}>
							Aprobar
						</Button>
					</Popconfirm>

					<Button type="danger" onClick={handleOnclickRechazar}>
						Rechazar
					</Button>
				</Space>
			</div>
			{/* <ModalPDF
				ruta={url}
				visible={visible}
				handleOnOkModal={handleOnOkModal}
				estadoDetalleEnvio={estadoDetalleEnvio}
			/> */}

			<ModalVisorPdf
				visible={visible}
				urlPdf={url}
				onCerrar={() => setVisible(false)}
			/>
		</Container>
	);
}
