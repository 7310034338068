import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space, notification, message, Dropdown, Table } from "antd";
import useTurnos from "./hooks/useTurnos";
import useModalCrearTurno from "./hooks/useModalCrearTurno";
import { ModalCrearTurno } from "./components";

const TurnoPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Bandeja de Turnos" botonExtra={<Botones />}>
        <ListaTurnos />
        <ModalCrearTurno />
      </Container>
    </JotaiProvider>
  );
};

const Botones = () => {
  const { setOpen: setOpenModalCrearTurno } = useModalCrearTurno();
  return (
    <Button
      key="crear-turno"
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setOpenModalCrearTurno(true)}
    >
      Crear Turno
    </Button>
  );
};
const ListaTurnos = () => {
  const { loading, turnos, fetchTurnos } = useTurnos();

  useEffect(() => {
    const inicializar = () => {
      // if (open) {
      fetchTurnos();
      // setFormInstance(form);
      // }
    };
    inicializar();
  }, []);

  //const { setOpen: setOpenModalCrearProveedor } = useModalCrearProveedor();

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      align: "center",
    },
    {
      title: "Personas",
      dataIndex: "persona",
      align: "center",
    },
    {
      title: "Horario",
      dataIndex: "horario",
      align: "center",
    },
    {
      title: "Horario refrigerio",
      dataIndex: "horarioRefrigerio",
      align: "center",
    },
    {
      title: "Tolerancia",
      dataIndex: "tolerancia",
      align: "center",
    },
    // {
    //     title: 'Acciones',
    //     align: 'center',
    //     width: '10%',
    //     render: (fila) => <MenuAcciones requisito={fila} />
    // },
  ];

  return (
    <Table
      rowKey={(turnos) => turnos.id}
      dataSource={turnos}
      columns={columnas}
    />
  );
};
export default TurnoPage;
