import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider, useAtom, useSetAtom } from "jotai";
import { Container } from "components/Container";
import {Botones} from "./componentes/botones";
import TablaPublicaciones from "./componentes/tabla-publicaciones/TablaPublicaciones";

const ListarHorariosPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Bandeja de Publicaciones" botonExtra={<Botones/>}>
           <TablaPublicaciones/>
           {/*<ModalRechazarSolicitudVacaciones/> */}
        </Container>
    </JotaiProvider>
  )
}

export default ListarHorariosPage;