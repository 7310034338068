import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles
import "react-quill-emoji/dist/quill-emoji.css"; // Emoji styles
import Emoji from "react-quill-emoji";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Upload,
  Space,
  Form,
  Col,
  Row,
  Select,
  Modal,
  notification,
  Tag
} from "antd";
import { UploadOutlined, TagOutlined, FilterOutlined, MessageOutlined, CheckOutlined,
  EyeOutlined, BgColorsOutlined
} from "@ant-design/icons";
import { TwitterPicker } from "react-color";
import { Headers, TipoUsuario } from 'enums';
import { LoginService } from 'services/LoginService';
import ImgCrop from "antd-img-crop";
import { Etiqueta } from "enums/comunicaciones-cultura/Etiqueta";
import { Filtro } from "enums/comunicaciones-cultura/Filtro";
import { PublicacionService } from "services/comunicacion-cultura/PublicacionService";
import { useHistory, useParams } from "react-router-dom";
const { TextArea } = Input;
const { Text, Title } = Typography;

const FormEditarPublicacion = () => {
  const [value, setValue] = useState("");
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }], // Headers
      ["bold", "italic", "underline"], // Text styles
      ["emoji"], // Emoji picker button
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      ["link"], // Link option
      ["clean"], // Clear formatting
    ],
    clipboard: {
      matchVisual: false, // Desactiva estilos visuales automáticos
    },
    "emoji-toolbar": true, // Enable emoji toolbar
    "emoji-textarea": false, // Disable emoji textarea
    "emoji-shortname": true, // Allow emoji shortnames like ":smile:"
  };

  let { formularioId } = useParams();
  const [formPublicacionEditar] = Form.useForm();
  // const [notification, setNotification] = useState(false);
  const [previewLinks, setPreviewLinks] = useState(false);
  const [fileList, setFileList] = useState([]);
	const [uidList, setUidList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
  const [texto, setTexto] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [publicacion, setPublicacion] = useState({});
  const datos = {
		uid: texto,
	};
  const [modalElegirColor, setModalElegirColorVisible] = useState(false);
  const [color, setColor] = useState("#87D068");

  useEffect(() => {
		const fetch = async () => {
			try {
				const _formulario = await PublicacionService.buscarPorId(
					formularioId
				);
				setPublicacion(_formulario);

				console.log("publicacionBuscado", _formulario);
				

				formPublicacionEditar.setFieldsValue({
					id: _formulario.id,
					mensaje: _formulario.mensajeHTML,
          etiqueta: _formulario.etiqueta,
          color:_formulario.colorEtiqueta
          //filtro:_formulario.filtro.tipo
				});
        setValue(_formulario.mensajeHTML);
        setColor(_formulario.colorEtiqueta);
        // Mapear las imágenes al formato requerido
        if (_formulario.imagenes && Array.isArray(_formulario.imagenes)) {
          const formattedFileList = _formulario.imagenes.map((input, index) => {
            // Separar uid y url usando split
            const [uid, nombre, url] = input.split("||");
            
            return {
              uid: uid, // Identificador único
              name: uid, // Nombre mostrado
              status: "done", // Indica que ya está cargada
              url: url, // URL de la imagen existente
            };
          });
          
          console.log("formattedFileList", formattedFileList);
          setFileList(formattedFileList);
        } else {
          setFileList([]); // Si no hay imágenes, establecer como vacío
        }
        
				
			} catch (error) {
				console.error(error);
			}
		};
		fetch();
	}, [formularioId, formPublicacionEditar]);

  const props = {
		name: "file",
		multiple: true,
		action: process.env.REACT_APP_ENDPOINT + "comunicaciones-cultura/cargarDocumentos",
		headers: {
			// Authorization: "Bearer " + LoginService.getJwtToken(),
			// [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			// [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			// [Headers.TIPO_USUARIO]: "ADMIN"
      ["authorization"]: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
      [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN
		},
		data: datos,
		beforeUpload(file) {
      console.log("PPPPP::", file);
			setTexto(file.uid);
			return file;
		},
    onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }
	};

  const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
	};

  const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

  function handleOnClickCancelar() {
		// history.push("/personal/trabajador");
	}

  const handleCancel = () => setPreviewVisible(false);

  const htmlToPlainTextWithNewlines = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Reemplazar etiquetas `<br>` con saltos de línea
    tempElement.querySelectorAll("br").forEach((br) => br.replaceWith("\n"));

    // Reemplazar etiquetas `<p>` con texto seguido de saltos de línea
    tempElement.querySelectorAll("p").forEach((p) => {
      const textNode = document.createTextNode("\n");
      p.appendChild(textNode);
    });

    // Obtener el texto con saltos respetados
    return tempElement.textContent.trim();
  };

  const onClickBtnActualizarPublicacion= async(form) =>{
    try{
      setLoading(true);
      let usuarioModificacion = {
        nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
        numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
      }

      // let filtroMapeado = {
      //   tipo:form.filtro,
      //   dato:null
      // }
      //form.filtro = filtroMapeado;
      //form.usuarioRegistro = usuarioRegistro;
      //form.imagenes = fileList.map((c) => c.uid)

      console.log("ff____:",form);
      publicacion.mensajeHTML = form.mensaje;
      publicacion.mensaje = htmlToPlainTextWithNewlines(form.mensaje);
      publicacion.etiqueta = form.etiqueta;
      //publicacion.filtro = filtroMapeado
      publicacion.usuarioModificacion = usuarioModificacion;
      publicacion.imagenes = fileList.map((c) => c.uid);
      publicacion.colorEtiqueta = color;

      const respuesta = await PublicacionService.actualizar(publicacion);     
      notification.success({
        message: "Operación exitosa",
        description: "Se actualizó el registro exitosamente",
      });
       setLoading(false);
       history.push(`/comunicaciones-cultura/publicaciones`);
    } catch (error) {
       setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrio un error al actualizar la publicación",
      });
    }
  }

  const handleOnClickVerListaColores = () => {
    setModalElegirColorVisible(true);
  };

  const onCerrarModalColor = () => {
    setModalElegirColorVisible(false);
  };

  const onChangeColor = (color) => {
    setColor(color.hex);
    setModalElegirColorVisible(false);
  };

  return (
   <>
   <style>
        {`
        .custom-quill .ql-container {
          height: 200px; /* Altura total del contenedor */
        }

        .custom-quill .ql-editor {
          min-height: 150px; /* Altura mínima del área de edición */
          max-height: 400px; /* Opcional: Altura máxima del área de edición */
          overflow-y: auto;  /* Habilita el scroll vertical si es necesario */
        }
        `}
			</style>
      <Form
      layout="vertical"
      size="medium"
      form={formPublicacionEditar}
      onFinish={onClickBtnActualizarPublicacion}
    >
      <Form.Item
          label={
            <span>
              <MessageOutlined style={{ marginRight: 5 }} />
              Escribir Mensaje
            </span>
          }
          name="mensaje"
          rules={[
            {
              validator: (_, value) => {
                const trimmedValue = value?.replace(/<[^>]*>?/gm, "").trim();
                if (!trimmedValue) {
                  return Promise.reject(
                    new Error("El campo Mensaje es obligatorio.")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ReactQuill
            value={value}
            onChange={(val) => {
              setValue(val);
              formPublicacionEditar.setFieldsValue({ mensaje: val }); // Sincroniza con el formulario
            }}
            modules={modules}
            theme="snow"
            placeholder="Escribe algo..."
            className="custom-quill"
          />
        </Form.Item>
      <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={16}>
            <Form.Item
              label={
                <span>
                  <TagOutlined style={{ marginRight: 5 }} />
                  Agregar Etiqueta
                </span>
              }
              name="etiqueta"
              rules={[
                {
                  required: true,
                  message: "El campo Etiqueta es obligatorio.",
                },
              ]}
            >
              <Input
                placeholder="Ingrese etiqueta"
                onChange={() => formPublicacionEditar.validateFields(["etiqueta"])}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={" "} name="color">
              <Button
                type="default"
                icon={<BgColorsOutlined />}
                onClick={handleOnClickVerListaColores}
              >
                Color
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={" "}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.etiqueta !== curValues.etiqueta
              }
            >
              {({ getFieldValue }) => (
                <Tag color={color} style={{
                  alignSelf: "center",
                  marginRight: "16px",
                  borderRadius: "12px",
                }}>
                  {getFieldValue("etiqueta") || "Color de Etiqueta"}
                </Tag>
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={12}></Col> */}
        <Form.Item
          label={
            <span style={{ marginLeft: 10 }}>
              <UploadOutlined style={{ marginRight: 5 }} />
              Agregar Imagen
            </span>
          }
        >
          {/* <div style={{ marginLeft: "12px" }}>
            <ImgCrop rotate minZoom={0.5} aspect={2 / 1} cropperProps={{ restrictPosition: false }} grid>
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                accept={undefined}
              >
                {"+ Subir"}
              </Upload>
            </ImgCrop>
          </div> */}
          <div style={{ marginLeft: "12px" }}>
            <ImgCrop 
              rotationSlider
              aspectSlider 
              minZoom={0.5} 
              aspect={2 / 1} 
              cropperProps={{ restrictPosition: false }} 
              quality={1}
              showGrid
            >
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                accept={undefined}
              >
                {"+ Subir"}
              </Upload>
            </ImgCrop>
            <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} destroyOnClose>
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>

        </Form.Item>
       {/* </Row> */}
      {/* Checkboxes */}
      {/* <div style={{ marginBottom: "16px" }}>
        <Checkbox
          checked={notification}
          onChange={(e) => setNotification(e.target.checked)}
        >
          Enviar notificación push
        </Checkbox>
        <Checkbox
          style={{ marginLeft: "16px" }}
          checked={previewLinks}
          onChange={(e) => setPreviewLinks(e.target.checked)}
        >
          Previsualizar links
        </Checkbox>
      </div> */}
      <Row justify="center">
						<Space>
							{/* <Col>
								<Form.Item style={{ textAlign: "center" }}>
									<Button type="default"  icon={<EyeOutlined />} onClick={handleOnClickCancelar}>
									  Vista Previa
									</Button>
								</Form.Item>
							</Col> */}
							<Col>
								<Form.Item style={{ textAlign: "center" }}>
									<Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                  Publicar
									</Button>
								</Form.Item>
							</Col>
						</Space>
					</Row>
    </Form>
      <Modal
        open={modalElegirColor}
        title="Selecciona el color de la etiqueta"
        footer={null}
        onCancel={onCerrarModalColor}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        destroyOnClose
      >
        <TwitterPicker
          colors={[
            "#FF6900",
            "#FCB900",
            "#7BDCB5",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            "#ABB8C3",
            "#EB144C",
            "#F78DA7",
            "#9900EF",
            "#87d068",
          ]}
          color={color}
          onChangeComplete={onChangeColor}
        />
      </Modal>
   </> 
  );
};
export default FormEditarPublicacion;
