import React from "react";
import { Tabs } from "antd";
import { SolicitudesPendientesAprobacionPage } from "./pendiente-aprobacion";
import { ConsultaSolicitudesPage } from "./consulta-solicitudes";
import { TabCalendario } from "./components";

const SolicitudesAusenciaPage = () => {
    return (
        <ContainerTabs>
            <Tabs destroyInactiveTabPane={true}>
                <Tabs.TabPane key="solicitudes-pendientes-aprobacion" tab="Solicitudes pendientes de aprobación">
                    <SolicitudesPendientesAprobacionPage />
                </Tabs.TabPane>
                <Tabs.TabPane key="consultar-solicitudes" tab="Consultar solicitudes">
                    <ConsultaSolicitudesPage />
                </Tabs.TabPane>
                <Tabs.TabPane key="calendario" tab="Calendario">
                    <TabCalendario />
                </Tabs.TabPane>
            </Tabs>
        </ContainerTabs>
    );
};

const ContainerTabs = ({ children }) => {
    return (
        <div style={{ padding: "10px 20px", background: "white" }}>
            {children}
        </div>
    );
};

export default SolicitudesAusenciaPage;
