import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { turnosAtom } from "../store/store";
import { TurnoService } from "services/asistencias/TurnoService";

const useTurnos = () => {

    const [turnos, setTurnos] = useAtom(turnosAtom);
    const [loading, setLoading] = useState(true);

    const fetchTurnos = async () => {
        setLoading(true);
        const response = await TurnoService.listar();
        setTurnos(response);
        setLoading(false);
    }

    return {
        loading,
        turnos,
        fetchTurnos
    };
}

export default useTurnos;
