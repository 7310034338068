import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles
import "react-quill-emoji/dist/quill-emoji.css"; // Emoji styles
import Emoji from "react-quill-emoji";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Upload,
  Space,
  Form,
  Col,
  Row,
  Select,
  Modal,
  notification,
  message,
  Tag,
} from "antd";
import {
  UploadOutlined,
  TagOutlined,
  FilterOutlined,
  MessageOutlined,
  CheckOutlined,
  EyeOutlined,
  UsergroupAddOutlined,
  ChromeOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { TwitterPicker } from "react-color";
import { useAtom } from "jotai";
import { Headers, TipoUsuario } from "enums";
import { LoginService } from "services/LoginService";
import ImgCrop from "antd-img-crop";
import { Etiqueta } from "enums/comunicaciones-cultura/Etiqueta";
import { Filtro } from "enums/comunicaciones-cultura/Filtro";
import { PublicacionService } from "services/comunicacion-cultura/PublicacionService";
import ModalCargaExcel from "./ModalCargaExcel";
import { useHistory } from "react-router-dom";
import { colaboradoresAgregadosAtom } from "../../CrearPublicacionStore";
import { FormatoCarga } from "enums/FormatoCarga";
const { TextArea } = Input;
const { Text, Title } = Typography;
Quill.register("modules/emoji", Emoji);

const FormCrearPublicacion = () => {
  const [value, setValue] = useState("");
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }], // Headers
      ["bold", "italic", "underline"], // Text styles
      ["emoji"], // Emoji picker button
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      ["link"], // Link option
      ["clean"], // Clear formatting
    ],
    clipboard: {
      matchVisual: false, // Desactiva estilos visuales automáticos
    },
    "emoji-toolbar": true, // Enable emoji toolbar
    "emoji-textarea": false, // Disable emoji textarea
    "emoji-shortname": true, // Allow emoji shortnames like ":smile:"
  };

  const [formPublicacion] = Form.useForm();
  const [previewLinks, setPreviewLinks] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uidList, setUidList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [texto, setTexto] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [modalExcelVisible, setModalExcelVisible] = useState(false);
  const [estadoCarga, setEstadoCarga] = useState(undefined);
  const [listaRespuesta, setListaRespuesta] = useAtom(
    colaboradoresAgregadosAtom
  );
  const [archivoExcel, setArchivoExcel] = useState([]);
  const [modalElegirColor, setModalElegirColorVisible] = useState(false);
  const [color, setColor] = useState("#87D068");

  const datos = {
    uid: texto,
  };

  const cleanHTML = (html) => {
    return html.replace(/<p><br><\/p>/g, "");
  };

  const onChangeColor = (color) => {
    setColor(color.hex);
    setModalElegirColorVisible(false);
  };

  const props = {
    name: "file",
    multiple: true,
    action:
      process.env.REACT_APP_ENDPOINT +
      "comunicaciones-cultura/cargarDocumentos",
    headers: {
      ["authorization"]: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
      [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN,
    },
    data: datos,
    beforeUpload(file) {
      setTexto(file.uid);
      return file;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  function handleOnClickCancelar() {
    // history.push("/personal/trabajador");
  }

  const handleCancel = () => setPreviewVisible(false);

  // const htmlToText = (html) => {
  //   const tempElement = document.createElement("div"); // Crea un elemento temporal
  //   tempElement.innerHTML = html; // Asigna el HTML como contenido
  //   return tempElement.textContent || tempElement.innerText; // Devuelve solo el texto
  // };

  const htmlToPlainTextWithNewlines = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Reemplazar etiquetas `<br>` con saltos de línea
    tempElement.querySelectorAll("br").forEach((br) => br.replaceWith("\n"));

    // Reemplazar etiquetas `<p>` con texto seguido de saltos de línea
    tempElement.querySelectorAll("p").forEach((p) => {
      const textNode = document.createTextNode("\n");
      p.appendChild(textNode);
    });

    // Obtener el texto con saltos respetados
    return tempElement.textContent.trim();
  };

  const onClickBtnCrearPublicacion = async (form) => {
    try {
      setLoading(true);
      let usuarioRegistro = {
        nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
        numeroDocumentoIdentidad:
          LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
      };

      let filtroMapeado = {
        tipo: form.filtro,
        datos: null,
      };
      // form.filtro = filtroMapeado;
      // form.usuarioRegistro = usuarioRegistro;
      form.imagenes = fileList.map((c) => c.uid);

      console.log("ff____:", form);

      console.log("ff2____:", htmlToPlainTextWithNewlines(form.mensaje));

      const __publicacion = {
        //mensajeHTML: cleanHTML(form.mensaje),
        mensajeHTML: form.mensaje,
        mensaje: htmlToPlainTextWithNewlines(form.mensaje),
        etiqueta: form.etiqueta,
        usuarioRegistro: usuarioRegistro,
        filtro: filtroMapeado,
        imagenes: form.imagenes,
        colorEtiqueta: color,
      };

      // // form.horaInicio = moment(form.horaInicioAuxiliar).format('HH:mm');
      // // form.horaFin = moment(form.horaFinAuxiliar).format('HH:mm');
      // form.usuarioRegistro = usuarioRegistro;
      // console.log("horarios", form);

      const formData = new FormData();
      if (form.filtro != null && form.filtro === Filtro.COLABORADOR) {
        formData.append("fileExcel", form.cargarArchivoExcel.file);
      }
      console.log("formulariofinal::", __publicacion);
      formData.append(
        "formulario",
        new Blob([JSON.stringify(__publicacion)], {
          type: "application/json",
        })
      );

      const respuesta = await PublicacionService.insertar(formData);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró la publicación exitosamente",
      });
      setLoading(false);
      history.push(`/comunicaciones-cultura/publicaciones`);
    } catch (error) {
      setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrio un error al registrar la publicación",
      });
    }
  };

  const onCerrarModal = () => {
    setModalExcelVisible(false);
  };

  const onCerrarModalColor = () => {
    setModalElegirColorVisible(false);
  };

  const onChangeFiltro = (value) => {
    // if (Filtro.COLABORADOR === value) {
    //   setModalExcelVisible(true);
    // }
    // setEstadoCarga(undefined);
    // setModalExcelVisible(false);
    //setColaboradoresAgregados([]);
    setListaRespuesta([]);
    if (value !== null) {
      setEstadoCarga(value);
    }
  };

  const onClickCargarBoton = () => {
    if (estadoCarga === Filtro.COLABORADOR) {
      setModalExcelVisible(true);
    }
  };

  const handleOnClickVerListaColores = () => {
    setModalElegirColorVisible(true);
  };

  const propsCargaExcel = {
    onRemove: (file) => {
      setArchivoExcel([]);

      return {
        setArchivoExcel: [],
      };
    },
    beforeUpload: (file) => {
      if (
        file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setArchivoExcel(file);
        return false;
      }
    },
    archivoExcel,
  };

  return (
    <>
      <style>
        {`
        .custom-quill .ql-container {
          height: 200px; /* Altura total del contenedor */
        }

        .custom-quill .ql-editor {
          min-height: 150px; /* Altura mínima del área de edición */
          max-height: 400px; /* Opcional: Altura máxima del área de edición */
          overflow-y: auto;  /* Habilita el scroll vertical si es necesario */
        }
        `}
			</style>
      <Form
        layout="vertical"
        size="medium"
        form={formPublicacion}
        onFinish={onClickBtnCrearPublicacion}
      >
        <Form.Item
          label={
            <span>
              <MessageOutlined style={{ marginRight: 5 }} />
              Escribir Mensaje
            </span>
          }
          name="mensaje"
          rules={[
            {
              validator: (_, value) => {
                const trimmedValue = value?.replace(/<[^>]*>?/gm, "").trim();
                if (!trimmedValue) {
                  return Promise.reject(
                    new Error("El campo Mensaje es obligatorio.")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ReactQuill
            value={value}
            onChange={(val) => {
              setValue(val);
              formPublicacion.setFieldsValue({ mensaje: val }); // Sincroniza con el formulario
            }}
            modules={modules}
            theme="snow"
            placeholder="Escribe algo..."
            className="custom-quill"
          />
        </Form.Item>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={16}>
            <Form.Item
              label={
                <span>
                  <TagOutlined style={{ marginRight: 5 }} />
                  Agregar Etiqueta
                </span>
              }
              name="etiqueta"
              rules={[
                {
                  required: true,
                  message: "El campo Etiqueta es obligatorio.",
                },
              ]}
            >
              <Input
                placeholder="Ingrese etiqueta"
                onChange={() => formPublicacion.validateFields(["etiqueta"])}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={" "} name="color">
              <Button
                type="default"
                icon={<BgColorsOutlined />}
                onClick={handleOnClickVerListaColores}
              >
                Color
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={" "}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.etiqueta !== curValues.etiqueta
              }
            >
              {({ getFieldValue }) => (
                <Tag color={color} style={{
                  alignSelf: "center",
                  marginRight: "16px",
                  borderRadius: "12px",
                }}>
                  {getFieldValue("etiqueta") || "Color de Etiqueta"}
                </Tag>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  <FilterOutlined style={{ marginRight: 5 }} />
                  Filtrar
                </span>
              }
              name="filtro"
              rules={[
                {
                  required: false,
                  // message: "El campo Filtrar es obligatorio.",
                },
              ]}
            >
              <Select
                placeholder="Seleccione filtro"
                onChange={onChangeFiltro}
                allowClear
              >
                <Select.Option value={Filtro.COLABORADOR}>
                  {Filtro.COLABORADOR}
                </Select.Option>
                {/* <Select.Option value={Filtro.CARGO}>
                {Filtro.CARGO}
              </Select.Option>*/}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {estadoCarga != undefined && estadoCarga === Filtro.COLABORADOR && (
              <Form.Item
                label={
                  <span style={{ marginLeft: 10 }}>
                    <UsergroupAddOutlined style={{ marginRight: 5 }} />
                    Seleccionar colaboradores
                  </span>
                }
                name="cargarArchivoExcel"
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      archivoExcel.length !== 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Seleccione el Archivo Excel")
                          ),
                  },
                ]}
              >
                <Upload
                  {...propsCargaExcel}
                  maxCount={1}
                  accept={FormatoCarga.EXCEL.extension}
                >
                  <Button block style={{ whiteSpace: "normal", width: "100%" }}>
                    Cargar Archivo Excel&nbsp;
                  </Button>
                </Upload>
              </Form.Item>
              // <Form.Item
              //   label={
              //     <span style={{ marginLeft: 10 }}>
              //       <UsergroupAddOutlined style={{ marginRight: 5 }} />
              //       Seleccionar los colaboradores
              //     </span>
              //   }>
              //   <Button onClick={onClickCargarBoton}>
              //     Cargar archivo excel
              //   </Button>
              // </Form.Item>
            )}
          </Col>
        </Row>

        <Form.Item
          label={
            <span style={{ marginLeft: 10 }}>
              <UploadOutlined style={{ marginRight: 5 }} />
              Agregar Imagen
            </span>
          }
        >
          <div style={{ marginLeft: "12px" }}>
            <ImgCrop
              rotationSlider
              aspectSlider
              minZoom={0.5}
              aspect={2 / 1}
              cropperProps={{ restrictPosition: false }}
              quality={1}
              showGrid
            >
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                accept={undefined}
              >
                {"+ Subir"}
              </Upload>
            </ImgCrop>
            {/* Agregar el texto informativo sobre las dimensiones */}
            <p style={{ 
              marginTop: "8px", 
              fontSize: "12px", 
              color: "#8c8c8c", // color gris claro
              textAlign: "left" 
            }}>
              <strong>Dimensión ideal de las imagenes:</strong> ancho: 720px x alto: 500px
            </p>
            <Modal
              open={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
              destroyOnClose
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>
        </Form.Item>

        {/* Checkboxes */}
        {/* <div style={{ marginBottom: "16px" }}>
        <Checkbox
          checked={notification}
          onChange={(e) => setNotification(e.target.checked)}
        >
          Enviar notificación push
        </Checkbox>
        <Checkbox
          style={{ marginLeft: "16px" }}
          checked={previewLinks}
          onChange={(e) => setPreviewLinks(e.target.checked)}
        >
          Previsualizar links
        </Checkbox>
      </div> */}
        <Row justify="center">
          <Space>
            {/* <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="default"
                  icon={<EyeOutlined />}
                  onClick={handleOnClickCancelar}
                >
                  Vista Previa
                </Button>
              </Form.Item>
            </Col> */}
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  htmlType="submit"
                  loading={loading}
                >
                  Publicar
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
      {/* <ModalCargaExcel visibleModal={modalExcelVisible} onCerrarModal={onCerrarModal} /> */}
      <Modal
        open={modalElegirColor}
        title="Selecciona el color de la etiqueta"
        footer={null}
        onCancel={onCerrarModalColor}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        destroyOnClose
      >
        <TwitterPicker
          colors={[
            "#FF6900",
            "#FCB900",
            "#7BDCB5",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            "#ABB8C3",
            "#EB144C",
            "#F78DA7",
            "#9900EF",
            "#87d068",
          ]}
          color={color}
          onChangeComplete={onChangeColor}
        />
      </Modal>
    </>
  );
};
export default FormCrearPublicacion;
