import React, { useEffect } from 'react';
import { Provider as JotaiProvider } from "jotai";
import { Button, Col, Form, Row, Select, Typography, Upload } from 'antd';
import { useDragAndDropFirmantes, useCrearFlujoFirma, useTiposDocumento, useFirmantes } from './hooks';
import { DeleteOutlined, HolderOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DebounceSelect } from './components';
import useColaboradores from './hooks/useColaboradores';
import styled from '@emotion/styled';

const CrearFlujoFirmaPage = () => {
	return (
		<JotaiProvider>
			<div style={{ height: "calc(100vh - 120px)", display: "flex", flexDirection: "column" }}>
				<Formulario />
				<Botones />
			</div>
		</JotaiProvider>
	);
};

const Formulario = () => {

	const [form] = Form.useForm();
	const { setFormInstance } = useCrearFlujoFirma();
	const { colaboradores, setColaboradores, fetchColaboradores } = useColaboradores();
	const { tiposDocumento, fetchTiposDocumento } = useTiposDocumento();
	const { coloresAsignados, adicionarFirmante, removerFirmante, onDragEnd } = useDragAndDropFirmantes();

	useEffect(() => {
		setFormInstance(form);
		fetchTiposDocumento();
	}, []);

	return (
		<Form
			form={form}
			layout='vertical'
			autoComplete="off"
			initialValues={{
				firmantes: [{
					color: coloresAsignados[0]
				}],
				// color: getRandomColor()
			}}
			style={{
				height: "calc(100vh - 210px)", paddingTop: "20px", paddingLeft: "15%", paddingRight: "15%"
			}}
		>
			<div>
				<Typography.Title level={5}>Nombre del documento</Typography.Title>
				<Form.Item
					name="tipoDocumentoId"
					rules={[
						{
							required: true,
							message: "Debe seleccionar un tipo de documento.",
						},
					]}
				>
					<Select
						style={{
							width: '100%',
						}}
						options={tiposDocumento}
					/>
				</Form.Item>
			</div>

			<div>
				<Typography.Title level={5}>Añadir documento</Typography.Title>
				<Form.Item
					name="fileDocumento"
					valuePropName="fileList"
					getValueFromEvent={(e) => {
						return Array.isArray(e) ? e : e?.fileList;
					}}
					rules={[
						{
							required: true,
							message: "Debe adjuntar un documento",
						},
					]}
				>
					<Upload.Dragger maxCount={1} beforeUpload={() => false}>
						<p className="ant-upload-drag-icon" style={{ marginBottom: "0px" }}>
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Haz clic ó arrastra tus archivo aquí</p>
						<p className="ant-upload-hint">
							Archivos soportados: PDF
						</p>
					</Upload.Dragger>
				</Form.Item>
			</div>

			<div style={{ marginTop: "20px" }}>
				<Typography.Title level={5}>Añadir Colaborador</Typography.Title>
				<Form.Item
					name={"colaborador"}
					rules={[
						{
							required: true,
							message: "Debe seleccionar un colaborador.",
						},
					]}
				>
					<DebounceSelect
						showSearch
						allowClear
						value={colaboradores}
						placeholder="Escribe el nombre del colaborador"
						fetchOptions={fetchColaboradores}
						onChange={(newValue) => {
							setColaboradores(newValue);
						}}
						style={{
							width: '100%',
						}}
					/>
				</Form.Item>
			</div>

			<div style={{ marginTop: "20px", paddingBottom: "60px" }}>
				<Typography.Title level={5}>Añadir firmantes</Typography.Title>

				<Form.List name="firmantes">
					{(fields, { add, remove, move }) => (
						<>
							<DragDropContext
								onDragEnd={(result) => onDragEnd(result, move)}
							>
								<Droppable droppableId="droppable-list">
									{(provided) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{fields.map(({ key, name, ...restField }, index) => (
												<Draggable key={key} draggableId={`${key}`} index={index}>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={{
																...provided.draggableProps.style,
																marginBottom: "10px",
																// borderLeft: "2px solid red"
																borderLeft: `4px solid ${coloresAsignados[index] || "#ccc"}`,

															}}
														>
															<Row
																gutter={24}
																style={{
																	border: "1px dashed #d9d9d9",
																	backgroundColor: "#fafafa",
																	marginLeft: 0,
																	marginRight: 0,
																	paddingTop: "10px",
																	marginTop: index > 0 ? "10px" : "0px"
																}}
															>
																<Col
																	span={1}
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		cursor: "grab",
																	}}
																	{...provided.dragHandleProps}
																>
																	<HolderOutlined
																		style={{
																			fontSize: "18px",
																			color: "#999",
																		}}
																	/>
																</Col>
																<Col span={21}>
																	<Form.Item
																		label="Firmante"
																		name={[name, 'colaborador']}
																		rules={[
																			{
																				required: true,
																				message: "Debe seleccionar un firmante.",
																			},
																		]}
																	>
																		<DebounceSelect
																			showSearch
																			allowClear
																			value={colaboradores}
																			placeholder="Escribe el nombre del firmante"
																			fetchOptions={fetchColaboradores}
																			onChange={(newValue) => {
																				setColaboradores(newValue);
																			}}
																			style={{
																				width: '100%',
																			}}
																		/>
																	</Form.Item>
																	<Form.Item name={[name, "color"]} hidden>
																		<input type="hidden" />
																	</Form.Item>
																</Col>
																<Col span={2} style={{ display: "flex", alignItems: "center" }}>
																	<DeleteOutlined
																		style={{ fontSize: "18px", color: "#ff4d4f" }}
																		// onClick={() => remove(name)}
																		onClick={() => removerFirmante(remove, index, name)}
																	/>
																</Col>
															</Row>

														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							<Form.Item>
								<Button type="dashed"
									// onClick={() => add()}
									onClick={() => adicionarFirmante(add)}
									icon={<PlusOutlined />}
									style={{ marginTop: "10px", marginBottom: "10px" }}>
									Añadir Firmante
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</div>
		</Form>
	)
}

const Botones = () => {

	const { loading, onClickBtnContinuar } = useCrearFlujoFirma();

	return (
		<FooterContainer>
			<Button size='large' type='primary' onClick={onClickBtnContinuar} loading={loading}>
				{`Siguiente >`}
			</Button>
		</FooterContainer>
	)
}

const FooterContainer = styled.div`
	background-color: #fff;
	height: 60px;
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: end;
	align-items: center;
	padding: 0px 20px
`

export default CrearFlujoFirmaPage;