import React from "react";
import { useAtom } from "jotai";
import { firmantesAtom, firmanteSeleccionadoAtom } from "../../store/ConfigurarFlujoFirmaStore";
import { useParams } from "react-router-dom";
import { FlujoFirmaService } from "services/notificacion";
import { Badge } from "antd";

const useFirmantes = () => {

    const { flujoFirmaId } = useParams();
    const [firmantes, setFirmantes] = useAtom(firmantesAtom);
    const [firmanteSeleccionado, setFirmanteSeleccionado] = useAtom(firmanteSeleccionadoAtom);

    const fetchFirmantes = async () => {
        const response = await FlujoFirmaService.listarFirmantes(flujoFirmaId);
        setFirmantes(
            response.firmantes.map((firmante) => ({
                value: firmante.id,
                label:
                    <span>
                        <Badge color={firmante.color} text={firmante.nombreCompleto} size="large" />
                    </span>,
                color: firmante.color
            }))
        );
        if (response.firmantes.length && response.firmantes.length > 0) {
            // setFirmanteSeleccionado(response.firmantes[0].id)
            setFirmanteSeleccionado({
                id: response.firmantes[0].id,
                color: response.firmantes[0].color
            })
        }
    };

    const seleccionarFirmante = (value, data) => {
        setFirmanteSeleccionado({
            id: value,
            color: data.color
        });
        // console.log("data==>", data);
    }

    return {
        firmantes,
        firmanteSeleccionado,
        fetchFirmantes,
        seleccionarFirmante
    }

}

export default useFirmantes;