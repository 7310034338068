import { useAtom } from "jotai";
import { fechaSeleccionadaAtom, formInstanceAtom, solicitudesAtom } from "../store/TabCalendarioStore";
import { SolicitudAusenciaService } from "services/VacacionesService";

const useCalendario = () => {
    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [fechaSeleccionada, setFechaSeleccionada] = useAtom(fechaSeleccionadaAtom);
    // const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);
    const [solicitudes, setSolicitudes] = useAtom(solicitudesAtom);

    const onClickBtnMostrarCalendario = () => {
        formInstance.validateFields()
            .then(async (formulario) => {
                fetchSolicitudesPorColaborador(formulario.periodo);
                setFechaSeleccionada(formulario.periodo);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    // const loadMoreColaboradores = async () => {
    //     fetchSolicitudesPorColaborador(fechaSeleccionada, pagina + 1);
    // }

    const fetchSolicitudesPorColaborador = async (fecha) => {
        const solicitudes = await SolicitudAusenciaService.getCalendario({
            mes: fecha.month() + 1,
            anio: fecha.year()
        });
        setSolicitudes(solicitudes);
    }

    const getDias = () => {
        return Array.from({ length: fechaSeleccionada.daysInMonth() }, (_, index) => index + 1);
    }

    return {
        solicitudes,
        // pagina,
        // colaboradores,
        // totalColaboradores,
        formInstance,
        fechaSeleccionada,
        // loadMoreColaboradores,
        // setPagina,
        getDias,
        setFormInstance,
        onClickBtnMostrarCalendario
    };
};

export default useCalendario;
