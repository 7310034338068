import { useAtom } from "jotai";
import { colaboradoresAtom } from "../store/Store";
import ColaboradorService from "services/ColaboradorService";

const useColaboradores = () => {

    const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);

    const fetchColaboradores = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim()
        });

        return respuesta.colaboradores.map((colaborador) => ({
            label: colaborador.nombreCompleto,
            value: colaborador.id,
        }));

    };

    return {
        colaboradores,
        setColaboradores,
        fetchColaboradores
    }

}

export default useColaboradores;