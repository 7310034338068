import { Drawer } from 'antd';
import React from 'react';
import { useDrawerActualizarColaboradores } from '../hooks';

const DrawerActualizarColaboradores = () => {

    const { open, cerrarDrawer } = useDrawerActualizarColaboradores();

    return (
        <Drawer title="Actualizar Colaboradores" placement="right" open={open} onClose={cerrarDrawer} >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Drawer>
    );
};

export default DrawerActualizarColaboradores;