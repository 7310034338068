import React, { useState, useContext } from "react";
import { Modal, Typography, Comment, List, Form, Input, Button, Checkbox, Row, Col, Tag } from "antd";
import * as atoms from "../ListarConsultasColaboradorStore";
import { useAtom } from "jotai";
import { InfoCircleTwoTone, UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DetalleConsultaColaboradorService } from "services/DetalleConsultaColaboradorService"
import { EstadoConsulta } from "enums/EstadoConsulta";
import { SecurityContext } from "context/SecurityContextProvider";

const ModalVerMensajeConsulta = () => {

    const [visibleModalConsulta, setVisibleModalConsulta] = useAtom(atoms.visibleModalConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const [detalleConsulta, setDetalleConsulta] = useAtom(atoms.detalleConsultaAtom);
    const { Text } = Typography;
    const [comentario, setComentario] = useState("");
    const [loading, setLoading] = useState(false);
    const { TextArea } = Input;
    const [formComentario] = Form.useForm();
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const onCancelModal = () => {
        formComentario.setFieldsValue({ valor: "" });
        setVisibleModalConsulta(false);
    }
    const handleOnChangeComentario = (e) => {

        setComentario(e.target.value);
    }
    const handleOnClickAgregar = async () => {

        if (comentario.trim()) {
            try {
                setLoading(true);
                const detalleIdAResponder = detalleConsulta[detalleConsulta.length - 1].id;
                //console.log("detalleID",detalleIdAResponder);
                const detalleConsultaAgregada =
                {
                    id: detalleIdAResponder,
                    consultaColaboradorId: consultaSeleccionada.id,
                    usuarioId: getUsuarioAutenticado().id,
                    respuesta: comentario.trim(),
                }
                const _detalleConsultaAgregada = await DetalleConsultaColaboradorService.insertar(detalleConsultaAgregada);
                let detalleConsultaCopiada = [...detalleConsulta];
                detalleConsultaCopiada = detalleConsultaCopiada.filter((detalle) => detalle.id !== detalleIdAResponder);
                setDetalleConsulta([...detalleConsultaCopiada, _detalleConsultaAgregada]);
                formComentario.setFieldsValue({ valor: "" });
                setComentario("");
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }

        }
    }
    return (
        <Modal
            visible={visibleModalConsulta}
            onCancel={onCancelModal}
            footer={null}
            title={<span><InfoCircleTwoTone style={{ fontSize: '20px' }} /> {""}{consultaSeleccionada.asunto}</span>}
            width={800}
            destroyOnClose={true}
        >
            {consultaSeleccionada.estadoConsulta === EstadoConsulta.RESUELTA && (
                <Tag color="#F7914F " icon={<ExclamationCircleOutlined />} >
                    Esta solicitud ya se encuentra cerrada y no podrá agregar más comentarios.
                </Tag>
            )}
            {detalleConsulta.length > 0 && (

                <List
                    //rowKey={(detalle)=>detalle.id}
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={detalleConsulta}
                    renderItem={item => (
                        <li
                            key={item.id}
                        >
                            <Comment
                                author={item.nombreCompletoColaborador}
                                avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }} />}
                                content={item.mensaje}
                                datetime={new Date(item.fechaRegistroMensaje).toLocaleString()}
                            >
                                {(item.detalleConsultaRespuesta != null && item.detalleConsultaRespuesta.length > 0) && (
                                    item.detalleConsultaRespuesta.map((detalleRespuesta) => {
                                        return (
                                            <Comment
                                                author={detalleRespuesta.usuarioNombreCompleto}
                                                avatar={<UserOutlined style={{ fontSize: '16px', color: '#099359' }} />}
                                                content={detalleRespuesta.respuesta}
                                                datetime={new Date(detalleRespuesta.fechaRegistroRespuesta).toLocaleString()}
                                            />
                                        )
                                    })

                                )}
                            </Comment>
                        </li>

                    )}
                />


            )}

            {consultaSeleccionada.estadoConsulta === EstadoConsulta.ABIERTO && (
                <Comment
                    content={

                        <Form form={formComentario}>
                            <Form.Item name="valor">
                                <TextArea rows={4} onChange={handleOnChangeComentario} value={comentario} />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" onClick={handleOnClickAgregar} loading={loading} type="primary"> Agregar respuesta</Button>
                            </Form.Item>

                        </Form>
                    }
                />
            )}
            {/* Se agrega este código por el momento hasta rediseñar el modelo de consultas antiguas */}
            {detalleConsulta.length === 0 && (
                <Comment
                    author={consultaSeleccionada?.nombreCompleto}
                    avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }} />}
                    content={consultaSeleccionada?.mensaje}
                    datetime={consultaSeleccionada?.fechaRegistro}
                />

            )
            }
            {/* hasta aqui va el codigo agregado */}

        </Modal>
    )
}
export default ModalVerMensajeConsulta;