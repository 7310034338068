"use client";

import React,{useState} from "react";
import { Form, Select } from "antd";
import { useModalCrearReconocimiento } from "../hooks";
// import { ModalForm, ProFormGroup, ProFormSelect, ProFormText } from "@ant-design/pro-components";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ColaboradorService from "services/ColaboradorService";

const ModalCrearReconocimiento = () => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [colaboradores, setColaboradores] = useState(undefined);

  const { open, setOpen, crearReconocimiento } = useModalCrearReconocimiento();

  const onSearchColaboradores = async (value) => {
		if (value === "") {
			setColaboradores([]);
			return;
		}
		const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
			filtro: value.trim(),
		});
		setColaboradores(respuesta.colaboradores);
	};

  return (
    <Modal
      title="Crear Reconocimiento"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      destroyOnClose={true}
    >
      <Form layout="vertical" onFinish={crearReconocimiento}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Título"
              name="titulo"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el título ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese descripción",
                },
              ]}
            >
              <TextArea size="large" rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
						<Col span={24}>
							<Form.Item label="Colaborador" name="numeroDocumentoIdentidad"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el colaborador",
                },
              ]}>
								<Select
									style={{ width: "100%" }}
									allowClear
									showSearch
									placeholder="Ingrese responsable renovación"
									onSearch={onSearchColaboradores}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									// onClear={() => setFiltroColaborador(undefined)}
								>
									{colaboradores !== undefined &&
										colaboradores?.map((colaborador) => (
											<Select.Option
												key={colaborador.numeroDocumentoIdentidad}
												value={colaborador.numeroDocumentoIdentidad}
											>
												{colaborador.nombreCompleto}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						</Col>
            </Row>
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  // loading={loading}
                >
                  Aceptar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={() => setOpen(false)}> Cancelar</Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalCrearReconocimiento;
