import React, { useEffect, useContext } from "react";
import { Table, Tag, Space, Popconfirm, notification } from "antd";
// import { MenuAcciones } from "./MenuAcciones";
import {
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import { useAtom } from "jotai";
import { publicacionesAtom } from "../../ListarPublicacionesStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { PublicacionService } from "services/comunicacion-cultura/PublicacionService";
import { useHistory, useParams } from "react-router-dom";

const TablaPublicaciones = () => {
  // let { formularioId } = useParams();
  let history = useHistory();
  //const [solicitudesVacaciones, setSolicitudesVacaciones] = useAtom(atoms.solicitudesVacacionesAtom);
  const [publicaciones, setPublicaciones] = useAtom(publicacionesAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
 
  useEffect(() => {
    const CargarDatos = async () => {
      const respuesta = await PublicacionService.listar();
      console.log("respuesta", respuesta);
      setPublicaciones(respuesta);
    };
    CargarDatos();
  }, []);

  const columnas = [
    {
      title: "Mensaje",
      dataIndex: "mensajeReducido",
      align: "center",
    },
    {
      title: "Etiqueta",
      dataIndex: "etiqueta",
      align: "center",
      render: (text, fila) => {
        return <Tag color="blue">{fila.etiqueta}</Tag>;
      }
    },
    {
      title: "Acciones",
      align: "center",
      key: "acciones",
      align: "center",
      render: (fila) =>
        publicaciones.length >= 1 ? (
          <Space>
            <a
              onClick={() => handleOnClickEditarPublicacion(fila.id)}
              className="custom-color-element"
            >
              <EditOutlined /> Editar
            </a>
            <Popconfirm
              title="¿Está seguro de eliminar el registro seleccionado?"
              okText="Si"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleOnClickEliminarPubliacion(fila.id)}
            >
              <a style={{ color: "#CE3E3E" }}>
                {" "}
                <DeleteOutlined /> Eliminar
              </a>
            </Popconfirm>
          </Space>
        ) : null,
    },
    // {
    //   title: "Acciones",
    //   align: "center",
    //   render: (text, fila) => (
    //     <MenuAcciones
    //       solicitud={fila}
    //       solicitudVacaciones={solicitudesVacaciones}
    //       setSolicitudVacaciones={setSolicitudesVacaciones}
    //     />
    //   ),
    // },
  ];

  function handleOnClickEditarPublicacion(id) {
    history.push(`/comunicaciones-cultura/publicaciones/editar/${id}`);
  }

  async function handleOnClickEliminarPubliacion(id) {
    await PublicacionService.eliminar(id);
    setPublicaciones(publicaciones.filter((publicacion) => publicacion.id !== id));
    notification.success({
      message: "Operación exitosa",
      description: "Se eliminó el registro correctamente.",
    });
  }

  return (
    <Table
      rowKey={(publicaciones) => publicaciones.id}
      dataSource={publicaciones}
      columns={columnas}
    />
  );
};
export default TablaPublicaciones;
