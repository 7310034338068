import { BaseService } from "services/BaseService";

const FlujoFirmaService = {

    async crear(data) {
        const respuesta = await BaseService.post("/admin/flujo-firma", data);
        return respuesta.data;
    },

    async listarFirmantes(flujoFirmaId) {
        const respuesta = await BaseService.get(`/admin/flujo-firma/${flujoFirmaId}/firmantes`);
        return respuesta.data;
    },

    async getUrlDocumento(flujoFirmaId) {
        const respuesta = await BaseService.get(`/admin/flujo-firma/${flujoFirmaId}/url-documento`);
        return respuesta.data;
    },

    async getComponentes(flujoFirmaId) {
        const respuesta = await BaseService.get(`/admin/flujo-firma/${flujoFirmaId}/componentes`);
        return respuesta.data;
    },

    async guardarComponentes(flujoFirmaId, data) {
        const respuesta = await BaseService.post(`/admin/flujo-firma/${flujoFirmaId}/componentes`, data);
        return respuesta.data;
    },

    async notificar(flujoFirmaId) {
        const respuesta = await BaseService.post(`/admin/flujo-firma/${flujoFirmaId}/notificar`);
        return respuesta.data;
    },

}
export default FlujoFirmaService;