import React, { useState, useEffect } from "react";
import {
  Layout,
  Form,
  Input,
  Checkbox,
  Button,
  Calendar,
  Modal,
  TimePicker,
  Popconfirm,
  notification,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
import { Container } from "components/Container";
import { useHistory, useParams } from "react-router-dom";
import { TurnoService } from "services/asistencias/TurnoService";
import { LoginService } from "services/LoginService";

const { Header, Sider, Content } = Layout;

const ConfiguracionMensualPage = () => {
  moment.locale("es");
  let { turnoId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [turno, setTurno] = useState({});
  const [newEvent, setNewEvent] = useState({
    start: null,
    end: null,
    breakStart: null,
    breakEnd: null,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const _turno = await TurnoService.buscarPorId(turnoId);
        setTurno(_turno);
        console.log("_turnoMensual", _turno);

        form.setFieldsValue({
          nombre: _turno?.nombre,
        });
        console.log("turnoCreadoMensual", _turno?.nombre);
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, []);

  const openModal = (date) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (newEvent.start && newEvent.end && selectedDate) {
      setEvents({
        ...events,
        [selectedDate]: { ...newEvent },
      });
    }
    setIsModalOpen(false);
    setNewEvent({
      start: null,
      end: null,
      breakStart: null,
      breakEnd: null,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteEvent = (date) => {
    const updatedEvents = { ...events };
    delete updatedEvents[date];
    setEvents(updatedEvents);
  };

  const renderCell = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const event = events[formattedDate];
    if (event) {
      return (
        <div className="event-cell" style={{ backgroundColor: "#d9f7be" }}>
          <div className="event-header">
            <Popconfirm
              title="¿Eliminar evento?"
              onConfirm={() => deleteEvent(formattedDate)}
              okText="Sí"
              cancelText="No"
            >
              <DeleteOutlined className="delete-icon" />
            </Popconfirm>
          </div>
          <div className="event-time">
            <CheckCircleOutlined style={{ color: "#52c41a", marginRight: 8 }} />
            {event.start} - {event.end}
          </div>
        </div>
      );
    }
    return (
      <Button type="dashed" onClick={() => openModal(formattedDate)} block>
        +
      </Button>
    );
  };

  const onClickRetonar = () => {
    history.push("/asistencia/turnos");
  };

  // Nueva función para manejar guardar
  const handleSave = async () => {
    try {
      setLoading(true);
      
      let usuarioModificacion = {
        nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
        numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
      }

      // const configuracion = {
      //   mensual: Object.entries(events).map(([fecha, evento]) => ({
      //     fecha, // La clave (fecha) del objeto `events`
      //     inicioJornada: evento.start || null,
      //     inicioRefrigerio: evento.breakStart || null,
      //     terminoRefrigerio: evento.breakEnd || null,
      //     terminoJornada: evento.end || null,
      //   })),
      // };

      const configuracion = {
        mensual: Object.entries(events).reduce((acc, [fecha, evento]) => {
          acc[fecha] = {
            // fecha, // Fecha en formato "YYYY-MM-DD"
            activo: true, // Asumimos que todos los eventos son activos, ajusta según el caso
            inicioJornada: evento.start || null,
            inicioRefrigerio: evento.breakStart || null,
            terminoRefrigerio: evento.breakEnd || null,
            terminoJornada: evento.end || null,
          };
          return acc;
        }, {}),
      };
      
      // Ordenar las fechas de menor a mayor
      // const configuracionOrdenada = {
      //   mensual: Object.fromEntries(
      //     Object.entries(configuracion.mensual).sort(([fechaA], [fechaB]) =>
      //       new Date(fechaA) - new Date(fechaB)
      //     )
      //   ),
      // };

      console.log("Configuración generada:", configuracion);


      const turnoData = {
        id: turno.id,
        nombre: turno.nombre,
        tipo: turno.tipo,
        trabajaFestivos: form.getFieldValue("trabajaFestivos") || false,
        usuarioRegistro: turno.usuarioRegistro,
        usuarioModificacion: usuarioModificacion,
        estado: turno.estado,
        empresaId: turno.empresaId,
        configuracion: configuracion,
      };
      console.log("Turno Data::", turnoData); // Esto debería imprimirse en la consola al guardar
      
      const respuesta = await TurnoService.actualizar(turnoData);

      notification.success({
        message: "Operación exitosa",
        description: "Se registró la configuración del turno exitosamente",
      });
      setLoading(false);
      history.push(`/asistencia/turnos`);
    } catch (error) {
      setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrió un error en la configuración del turno",
      });
    }
  };

  const BotonExtra = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px",
        marginBottom: "16px",
      }}
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleSave}
        loading={loading}
      >
        Guardar
      </Button>
    </div>
  );

  return (
    <Container
      titulo={
        <div>
          <h5>Configuración de turno mensual</h5>
        </div>
      }
      onClickRetornar={onClickRetonar}
      botonExtra={<BotonExtra handleSave={handleSave} loading={loading} />}
    >
      <Content>
        <div style={{ display: "flex", gap: "16px", alignItems: "stretch" }}>
          <div
            style={{
              width: "30%",
              background: "#fff",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
            }}
          >
            <h3>Configuración</h3>
            <Form layout="vertical" 
              form={form}
              initialValues={{
                trabajaFestivos: false,
                tolerancia: 0
              }}
              >
              <Form.Item label="Nombre del turno" name="nombre">
                <Input  readOnly/>
              </Form.Item>
              <Form.Item name="trabajaFestivos" valuePropName="checked">
                <Checkbox>Trabaja Festivos</Checkbox>
              </Form.Item>
              <Form.Item label="Tolerancia" name="tolerancia">
                <Input type="number" placeholder="0" />
              </Form.Item>
            </Form>
          </div>
          <div
            style={{
              width: "70%",
              background: "#fff",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>Distribución de turno</h3>
            {/* <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px", marginBottom: "16px" }}>
              <Button type="primary" icon={<PlusOutlined />}>
                Guardar
              </Button>
              </div> */}
            {/* <div> */}
            <Calendar
              headerRender={({ value, onChange }) => {
                const current = value;
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Button
                      shape="circle"
                      style={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#1890ff",
                        color: "white",
                        marginRight: "8px",
                      }}
                      onClick={() =>
                        onChange(current.clone().subtract(1, "month"))
                      }
                      icon={<LeftOutlined />}
                    />
                    <h3
                      style={{
                        margin: "0 16px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {current.format("MMMM YYYY")}
                    </h3>
                    <Button
                      shape="circle"
                      style={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#1890ff",
                        color: "white",
                        marginLeft: "8px",
                      }}
                      onClick={() => onChange(current.clone().add(1, "month"))}
                      icon={<RightOutlined />}
                    />
                  </div>
                );
              }}
              dateCellRender={renderCell}
              style={{
                // border: "1px solid #e8e8e8",
                borderRadius: "8px",
              }}
              className="custom-calendar"
            />
            {/* </div> */}
          </div>
        </div>

        <Modal
          title="Agregar Horario"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form layout="vertical">
            <Form.Item label="Inicio de Jornada">
              <TimePicker
                format="HH:mm"
                onChange={(time) =>
                  setNewEvent({ ...newEvent, start: time.format("HH:mm") })
                }
                value={newEvent.start ? moment(newEvent.start, "HH:mm") : null}
              />
            </Form.Item>
            <Form.Item label="Fin de Jornada">
              <TimePicker
                format="HH:mm"
                onChange={(time) =>
                  setNewEvent({ ...newEvent, end: time.format("HH:mm") })
                }
                value={newEvent.end ? moment(newEvent.end, "HH:mm") : null}
              />
            </Form.Item>
            <Form.Item label="Inicio de Colación">
              <TimePicker
                format="HH:mm"
                onChange={(time) =>
                  setNewEvent({ ...newEvent, breakStart: time.format("HH:mm") })
                }
                value={
                  newEvent.breakStart
                    ? moment(newEvent.breakStart, "HH:mm")
                    : null
                }
              />
            </Form.Item>
            <Form.Item label="Fin de Colación">
              <TimePicker
                format="HH:mm"
                onChange={(time) =>
                  setNewEvent({ ...newEvent, breakEnd: time.format("HH:mm") })
                }
                value={
                  newEvent.breakEnd ? moment(newEvent.breakEnd, "HH:mm") : null
                }
              />
            </Form.Item>
          </Form>
        </Modal>

        {/* CSS Styles */}
        <style>
          {`
         .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
            text-align: center;
          }
          
          .custom-calendar .ant-picker-panel {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
          }
          .custom-calendar .ant-picker-cell {
            text-align: left;
            border: 1px solid #e8e8e8 !important;
            box-sizing: border-box;
          }
          .custom-calendar .ant-picker-cell:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .custom-calendar .ant-picker-cell-in-view.ant-picker-cell-today {
            border: 1px dashed #1890ff;
          }
          .custom-calendar .ant-picker-cell-in-view.ant-picker-cell-selected {
            background-color: #bae7ff;
            border-color: #1890ff;
          }
        `}
        </style>
      </Content>
    </Container>
  );
};

export default ConfiguracionMensualPage;
