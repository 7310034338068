import { useAtom } from "jotai";
import { componentesAtom, totalPaginasAtom, urlDocumentoAtom, zoomAtom } from "../../store/ConfigurarFlujoFirmaStore";
import { FlujoFirmaService } from "services/notificacion";
import { useParams } from "react-router-dom";

const useVisor = () => {

    const { flujoFirmaId } = useParams();
    const [zoom] = useAtom(zoomAtom);
    const [totalPaginas, setTotalPaginas] = useAtom(totalPaginasAtom);
    const [urlDocumento, setUrlDocumento] = useAtom(urlDocumentoAtom);
    const [componentes, setComponentes] = useAtom(componentesAtom);

    const onLoadSuccess = async (successEvent) => {
        setTotalPaginas(successEvent.numPages);
    };

    const fetchUrlDocumento = async () => {
        const response = await FlujoFirmaService.getUrlDocumento(flujoFirmaId);
        setUrlDocumento(response.urlDocumento);
    }

    const getKeyComponente = (tipoComponente) => {
        const componentesPorTipo = componentes.filter((c) => c.type === tipoComponente);
        return tipoComponente + (componentesPorTipo.length + 1);
    };

    const fetchComponentes = async () => {
        const response = await FlujoFirmaService.getComponentes(flujoFirmaId);
        setComponentes(response.componentes);
    }

    return {
        zoom,
        totalPaginas,
        urlDocumento,
        componentes,
        fetchComponentes,
        setComponentes,
        getKeyComponente,
        onLoadSuccess,
        fetchUrlDocumento
    }

}

export default useVisor;