import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.ASISTENCIA;

const TurnoService = {
  async insertar(turno) {
    const respuesta = await ApiService(URL_API).post("/turno", turno);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/turnos");
    return respuesta.data;
  },
  async buscarPorId(turnoId) {
		const respuesta = await ApiService(URL_API).get(`/turno/${turnoId}`);
		return respuesta.data;
	},
  async actualizar(turno) {
    const respuesta = await ApiService(URL_API).put("/turno", turno);
    return respuesta.data;
  },
};

export { TurnoService };
