"use client";

import React,{useState} from "react";
import { Form, Select } from "antd";
import { useModalCrearTurno } from "../hooks";
import { TipoTurno } from "enums/asistencia/TipoTurno";
// import { ModalForm, ProFormGroup, ProFormSelect, ProFormText } from "@ant-design/pro-components";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ModalCrearTurno = () => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const { open, setOpen, crearTurno } = useModalCrearTurno();

  return (
    <Modal
      title="Crear Turno"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      destroyOnClose={true}
    >
      <Form layout="vertical" onFinish={crearTurno}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el nombre ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label="Tipo"
              name="tipo"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el tipo",
                },
              ]}
            >
              <Select
                  placeholder="Seleccione el tipo"
                  allowClear
              >
                  <Select.Option value={TipoTurno.SEMANAL}>
                      {TipoTurno.SEMANAL}
                  </Select.Option>
                  <Select.Option value={TipoTurno.MENSUAL}>
                      {TipoTurno.MENSUAL}
                  </Select.Option>
                  <Select.Option value={TipoTurno.ROTATIVOS}>
                      {TipoTurno.ROTATIVOS}
                  </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  // loading={loading}
                >
                  Aceptar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={() => setOpen(false)}> Cancelar</Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalCrearTurno;
