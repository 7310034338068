import React, { useContext, useState, useEffect} from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  Button,
  Switch,
  Row,
  Col,
  notification
} from "antd";
import { FilterOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { Provider as JotaiProvider } from "jotai";
import { useHistory } from "react-router-dom";
import { Container } from "components/Container";
import { SecurityContext } from "context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";
import { FormularioNotificarService } from "services/FormularioNotificarService";
import ColaboradorService from "../../../../../services/ColaboradorService";
import { LoginService } from "services/LoginService";
import { AsignacionService } from "services/asistencias/AsignacionService";

// const { Header, Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

// const AsignacionMasivaCrearPage = () => {
//   const history = useHistory();
//   const [form] = Form.useForm();
//   const [workers, setWorkers] = useState([]);
//   const [colaboradores, setColaboradores] = useState([]);
//   const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState([]);
//   const { getUsuarioAutenticado } = useContext(SecurityContext);
//   const columns = [
//     {
//       title: "Nombre",
//       dataIndex: "nombre",
//       key: "nombre",
//     },
//     {
//       title: "RUT",
//       dataIndex: "rut",
//       key: "rut",
//     },
//     {
//       title: "Sucursal",
//       dataIndex: "sucursal",
//       key: "sucursal",
//     },
//     {
//       title: "Cargo",
//       dataIndex: "cargo",
//       key: "cargo",
//     },
//   ];

//   const handleAddWorker = () => {
//     const newWorker = {
//       key: workers.length + 1,
//       nombre: `Trabajador ${workers.length + 1}`,
//       rut: "12345678-9",
//       sucursal: "Casa Central",
//       cargo: "Empleado",
//     };
//     setWorkers([...workers, newWorker]);
//   };

//   const onChangeColaboradoresSeleccionados = (value) => {
//     setColaboradorSeleccionado(value);
//   };
//   const onSearchColaboradores = async (value) => {
//     const _respuesta =
//       await FormularioNotificarService.listarColaboradoresPorEmpresaIdConFiltro(
//         {
//           empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
//           filtro: value.trim(),
//           login: getUsuarioAutenticado().login,
//         }
//       );
//     setColaboradores(_respuesta);
//     if (value === "") {
//       form.setFieldsValue({
//         buscarColaborador: "",
//       });
//       setColaboradorSeleccionado([]);
//     }
//   };
//   return (
//     <JotaiProvider>
//       <Container
//         titulo={
//           <div>
//             <h5>Asignación masiva</h5>
//           </div>
//         }
//         onClickRetornar={() => history.push("/asistencia/turnos")}
//         // botonExtra={<Botones />}
//       >
//         <Form form={form} layout="vertical">
//           <Row gutter={24}>
//             <Col span={8}>
//               <Form.Item label="Turno" name="turno">
//                 <Select placeholder="Seleccione un turno">
//                   <Option value="administrativo">Turno Administrativo</Option>
//                   <Option value="operativo">Turno Operativo</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="Fechas" name="fechas">
//                 <RangePicker />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="Grupo" name="grupo">
//                 <Select placeholder="Seleccione un grupo">
//                   <Option value="grupo1">Grupo 1</Option>
//                   <Option value="grupo2">Grupo 2</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={24}>
//             <Col span={16}>
//               <Form.Item name="buscarColaborador" label="Buscar colaborador">
//                 <Select
//                   placeholder="Ingrese los colaboradores"
//                   showSearch
//                   onSearch={onSearchColaboradores}
//                   //style={{width:"100%"}}
//                   onChange={onChangeColaboradoresSeleccionados}
//                   filterOption={(input, option) =>
//                     option.children
//                       .toLowerCase()
//                       .indexOf(input.toLowerCase()) >= 0
//                   }
//                 >
//                   {colaboradores !== undefined &&
//                     colaboradores.map((colaborador) => {
//                       return (
//                         <Select.Option
//                           key={colaborador.id}
//                           value={colaborador.id}
//                         >
//                           {colaborador.nombreCompleto}
//                         </Select.Option>
//                       );
//                     })}
//                 </Select>
//               </Form.Item>
//             </Col>

//             <Col span={8}>
//               <Form.Item label={" "}>
//                 <Button type="primary" onClick={handleAddWorker}>
//                   Agregar Colaborador
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={24}>
//             <Col span={12}></Col>
//             <Col span={12}>
//             <Button type="link">
//                   Opciones avanzadas
//                 </Button>
//             </Col>
//           </Row>

//           <div className="advanced-options">
//             <Form.Item label="Razón social" name="razon_social">
//               <Select placeholder="Seleccione razón social">
//                 <Option value="razon1">Razón 1</Option>
//                 <Option value="razon2">Razón 2</Option>
//               </Select>
//             </Form.Item>
//             <Form.Item label="Gerencia" name="gerencia">
//               <Select placeholder="Seleccione gerencia">
//                 <Option value="gerencia1">Gerencia 1</Option>
//                 <Option value="gerencia2">Gerencia 2</Option>
//               </Select>
//             </Form.Item>
//             <Form.Item label="Sucursal" name="sucursal">
//               <Select placeholder="Seleccione sucursal">
//                 <Option value="sucursal1">Sucursal 1</Option>
//                 <Option value="sucursal2">Sucursal 2</Option>
//               </Select>
//             </Form.Item>
//             <Form.Item label="Centro de costo" name="centro_costo">
//               <Select placeholder="Seleccione centro de costo">
//                 <Option value="centro1">Centro 1</Option>
//                 <Option value="centro2">Centro 2</Option>
//               </Select>
//             </Form.Item>
//           </div>
//         </Form>
//         <Table
//           columns={columns}
//           dataSource={workers}
//           footer={() => <Button type="primary">Guardar</Button>}
//         />
//       </Container>
//     </JotaiProvider>
//   );
// };

// const Botones = () => {
//   // return (
//   //   <Button type="primary" htmlType="submit" style={{ marginTop: "20px" }}>
//   //     Guardar
//   //   </Button>
//   // );
// };
const AsignacionMasivaCrearPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradoresAsignacion, setColaboradoresAsignacion] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false); // Controlar visibilidad
  const [camposColaboradores, setCamposColaboradores] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);
  const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(null);
  const [showCampo1, setShowCampo1] = useState(false);
  const [showCampo2, setShowCampo2] = useState(false);
  const [showCampo3, setShowCampo3] = useState(false);
  const [showCampo4, setShowCampo4] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtroCargo, setFiltroCargo] = useState(undefined);
  const [paginaActual, setPaginaActual] = useState(1);
  //const [pagina, setPagina] = useState(0);
	const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);
  
  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  useEffect(() => {
    async function cargarDatos() {
      const campos_colaborador =
        getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaboradores(campos_colaborador);

      asignarNombresColumnas(campos_colaborador);

      const campoEnviado =
        await ColaboradorService.camposColaboradorListarNuevo(
          getUsuarioAutenticado().empresaSeleccionada.id
        );

      //setPerfiles(campoEnviado.PERFIL);
      setSedes(campoEnviado.SEDE);
      setSubsedes(campoEnviado.SUBSEDE);
      setSecciones(campoEnviado.SECCION);
      setCargos(campoEnviado.CARGO);
    }
    cargarDatos();
  }, []);

  function asignarNombresColumnas(camposColaboradores) {
    console.log("camposColaboradores:::", camposColaboradores);
    if(camposColaboradores.length > 0 ){
      camposColaboradores.map((campoColaborador) => {
        if (
          campoColaborador.visible === true &&
          campoColaborador.campo === CampoColaborador.SEDE
        ) {
          setShowCampo1(campoColaborador.etiqueta);  
        } else if (
          campoColaborador.visible === true &&
          campoColaborador.campo === CampoColaborador.SUBSEDE
        ) {
          setShowCampo2(campoColaborador.etiqueta); 
        } else if (
          campoColaborador.visible === true &&
          campoColaborador.campo === CampoColaborador.SECCION
        ) {
          setShowCampo3(campoColaborador.etiqueta);  
        } else if (
          campoColaborador.visible === true &&
          campoColaborador.campo === CampoColaborador.CARGO
        ) {
          setShowCampo4(campoColaborador.etiqueta); 
        }
      });
    }           
  }

  async function handleOnChangeSede(value, option) {
    form.setFieldsValue({ sede: { nombre: option.children } });

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.SEDE
    );
    procesarCambios(campoEnviado);
  }

  async function onSelectSubsede(value, option) {
    const subsede = {
      id: option.value,
      nombre: option.children,
    };
    setSubsedeSeleccionada(subsede);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.SUBSEDE
    );
    // console.log(campoEnviado)
    procesarCambios(campoEnviado);
  }

  async function onSelectSeccion(value, option) {
    const seccion = {
      id: option.value,
      nombre: option.children,
    };
    setSeccionSeleccionada(seccion);
    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.SECCION
    );
    procesarCambios(campoEnviado);
  }

  function procesarCambios(campoEnviado) {
    if (campoEnviado.CARGO != null) {
      setCargos(campoEnviado.CARGO);
      form.setFieldsValue({ cargo: { id: "" } });
    }
    if (campoEnviado.SECCION != null) {
      setSecciones(campoEnviado.SECCION);
      form.setFieldsValue({ seccion: "" });
    }
    if (campoEnviado.SEDE != null) {
      setSedes(campoEnviado.SEDE);
      form.setFieldsValue({ sede: { id: "" } });
    }
    if (campoEnviado.SUBSEDE != null) {
      setSubsedes(campoEnviado.SUBSEDE);
      form.setFieldsValue({ subsede: "" });
    }
  }

  async function handleOnChangeCargo(value, option) {
    form.setFieldsValue({ cargo: { nombre: option.children } });
    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.CARGO
    );
    procesarCambios(campoEnviado);
  }

  const columns = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      key: "numeroDocumentoIdentidad",
    },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      key: "nombreCompleto",
    },
    ...[
      showCampo1 && {
        title: showCampo1,
        dataIndex: "sede",
        key: "sede",
      },
      showCampo2 && {
        title: showCampo2,
        dataIndex: "subsede",
        key: "subsede",
      },
      showCampo3 && {
        title: showCampo3,
        dataIndex: "seccion",
        key: "seccion",
      },
      showCampo4 && {
        title: showCampo4,
        dataIndex: "cargo",
        key: "cargo",
      },
    ].filter(Boolean), // Filtra los valores `null` o `false` si no están visibles
  ];  

  const handleSave = async () => {
    try {
      setLoading(true);
      
      let usuarioModificacion = {
        nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
        numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
      }

      // const turnoData = {
      //   id: turno.id,
      //   nombre: turno.nombre,
      //   tipo: turno.tipo,
      //   trabajaFestivos: form.getFieldValue("trabajaFestivos") || false,
      //   usuarioRegistro: turno.usuarioRegistro,
      //   usuarioModificacion: usuarioModificacion,
      //   estado: turno.estado,
      //   empresaId: turno.empresaId,
      //   configuracion: configuracion,
      // };
      // console.log("Turno Data::", turnoData); // Esto debería imprimirse en la consola al guardar
      
      //const respuesta = await TurnoService.actualizar(turnoData);

      notification.success({
        message: "Operación exitosa",
        description: "Se registró la configuración del turno exitosamente",
      });
      setLoading(false);
      history.push(`/asistencia/turnos`);
    } catch (error) {
      setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrió un error en la configuración del turno",
      });
    }
  };

  const BotonExtra = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px",
        marginBottom: "16px",
      }}
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleSave}
        loading={loading}
      >
        Guardar
      </Button>
    </div>
  );

  // const buscarColaboradoresAsignacion = async () => {
  //   console.log("cargoo::",form.getFieldValue(["cargo", "id"]));
  //   console.log("paginaActual::",paginaActual);
  //   const respuesta = await AsignacionService.listarColaboradoresToAsignacionFiltro({
  //     sedeId: null,
  //     subSedeId: null,
  //     seccionId: null,
  //     cargoId: form.getFieldValue(["cargo", "id"]),
  //     pagina: paginaActual - 1,
  //     cantidad: 2,
  //   });
  //     console.log("buscarColaboradoresAsignacion::", respuesta);
	// 		setColaboradoresAsignacion(respuesta.colaboradoresAsignaciones);
	// 		setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
  //     setTotalRegistros(respuesta.totalColaboradoresAsignaciones);
  // }
  const buscarColaboradoresAsignacion = async (pagina = paginaActual) => {
    console.log("cargoo::", form.getFieldValue(["cargo", "id"]));
    console.log("pagina::", pagina);
  
    const respuesta = await AsignacionService.listarColaboradoresToAsignacionFiltro({
      sedeId: null,
      subSedeId: null,
      seccionId: null,
      cargoId: form.getFieldValue(["cargo", "id"]),
      pagina: pagina - 1, // Ajusta si el backend usa un índice basado en cero
      cantidad: 2, // Cambia si necesitas un tamaño diferente
    });
  
    console.log("buscarColaboradoresAsignacion::", respuesta);
    setColaboradoresAsignacion(respuesta.colaboradoresAsignaciones);
    setTotalPaginas(respuesta.totalPaginas);
    setTotalRegistros(respuesta.totalColaboradoresAsignaciones);
  };
  
  // useEffect(async () => {
    // const respuesta = await SolicitudAusenciaService.listarPorGestionHumanaConPaginacion({
    //     usuario: getUsuarioAutenticado().numeroDocumentoIdentidad,
    //     cargo: filtroCargo,
    //     pagina: paginaActual - 1,
    //     cantidad: 10,
    // });
    // setSolicitudesAusencia(respuesta.data.solicitudesAusencia);
    // setTotalRegistros(respuesta.data.totalRegistros);
// }, [
//     filtroCargo,
// ]);

  
  const onClickFiltrar = async () => {
    setPaginaActual(1);
    buscarColaboradoresAsignacion();
		//setFiltroCargo(form.getFieldValue(["cargo", "id"]));
  };

//   const onCambiarPaginacion = (page, pageSize) => {
//     console.log("page", page);
//     console.log("pageSize", pageSize);
//     setPaginaActual(page);
//     buscarColaboradoresAsignacion();
// };
const onCambiarPaginacion = (page, pageSize) => {
  setPaginaActual(page); // Actualiza el estado de la página
  buscarColaboradoresAsignacion(page); // Llama a la función con la nueva página
};

  return (
    <JotaiProvider>
      <Container
        titulo={
          <div>
            <h5>Asignación masiva</h5>
          </div>
        }
        onClickRetornar={() => history.push("/asistencia/turnos")}
        botonExtra={<BotonExtra handleSave={handleSave} loading={loading} />}
      >
        <Form form={form} layout="vertical">
          {/* Campos del formulario principal */}
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Turno" name="turno">
                <Select placeholder="Seleccione un turno">
                  <Option value="administrativo">Turno Administrativo</Option>
                  <Option value="operativo">Turno Operativo</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Fechas" name="fechas">
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Grupo" name="grupo">
                <Select placeholder="Seleccione un grupo">
                  <Option value="grupo1">Grupo 1</Option>
                  <Option value="grupo2">Grupo 2</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={16}>
              <Form.Item name="buscarColaborador" label="Buscar colaborador">
                <Select
                  placeholder="Ingrese los colaboradores"
                  showSearch
                  onSearch={(value) => {}}
                  onChange={(value) => setColaboradorSeleccionado(value)}
                >
                  {colaboradores.map((colaborador) => (
                    <Select.Option key={colaborador.id} value={colaborador.id}>
                      {colaborador.nombreCompleto}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={" "}>
                <Button type="primary" ghost>
                  {/* onClick={handleAddWorker} */}
                  <PlusSquareOutlined />Agregar
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={16}></Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button type="link" onClick={toggleAdvancedOptions}>
                Búsqueda avanzada {showAdvancedOptions ? "▲" : "▼"}
              </Button>
            </Col>
          </Row>
          {/* Advanced Options */}
          {showAdvancedOptions && (
            <div
              className="advanced-options"
              style={{
                // padding: "16px",
                // border: "1px solid #e0e0e0",
                // borderRadius: "8px",
                // marginTop: "16px",
                transition: "opacity 0.3s ease-in-out",
                opacity: showAdvancedOptions ? 1 : 0,
              }}
            >
              <Row gutter={16}>
                {camposColaboradores.length > 0 &&
                  camposColaboradores.map((campoColaborador) => {
                    if (
                      campoColaborador.visible === true &&
                      campoColaborador.campo === CampoColaborador.SEDE
                    ) {
                      return (
                        <Col key={campoColaborador.nombre} span={8}>
                          <Form.Item
                            name={["sede", "id"]}
                            label={campoColaborador.etiqueta}
                            rules={
                              campoColaborador.campoObligatorio === true
                                ? [
                                    {
                                      required: true,
                                      message: `Seleccione ${campoColaborador.etiqueta}`,
                                    },
                                  ]
                                : []
                            }
                          >
                            <Select onChange={handleOnChangeSede}>
                              {sedes.length > 0 &&
                                sedes.map((sede) => {
                                  return (
                                    <Option key={sede.id} value={sede.id}>
                                      {sede.nombre}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                          <Form.Item name={["sede", "nombre"]} noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                        </Col>
                      );
                    } else if (
                      campoColaborador.visible === true &&
                      campoColaborador.campo === CampoColaborador.SUBSEDE
                    ) {
                      return (
                        <Col key={campoColaborador.nombre} span={8}>
                          <Form.Item
                            label={campoColaborador.etiqueta}
                            name="subsede"
                            rules={
                              campoColaborador.campoObligatorio === true
                                ? [
                                    {
                                      required: true,
                                      message: "Seleccione Subcentro de costo",
                                    },
                                  ]
                                : []
                            }
                          >
                            <Select
                              onSelect={onSelectSubsede}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {subsedes.length > 0 &&
                                subsedes.map((subsede) => {
                                  return (
                                    <Option key={subsede.id} value={subsede.id}>
                                      {subsede.nombre}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    } else if (
                      campoColaborador.visible === true &&
                      campoColaborador.campo === CampoColaborador.SECCION
                    ) {
                      return (
                        <Col key={campoColaborador.nombre} span={8}>
                          <Form.Item
                            label={campoColaborador.etiqueta}
                            name="seccion"
                            rules={
                              campoColaborador.campoObligatorio === true
                                ? [
                                    {
                                      required: true,
                                      message: "Seleccione Sección",
                                    },
                                  ]
                                : []
                            }
                          >
                            <Select
                              onSelect={onSelectSeccion}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {secciones.length > 0 &&
                                secciones.map((seccion) => {
                                  return (
                                    <Option key={seccion.id} value={seccion.id}>
                                      {seccion.nombre}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    } else if (
                      campoColaborador.visible === true &&
                      campoColaborador.campo === CampoColaborador.CARGO
                    ) {
                      return (
                        <Col key={campoColaborador.nombre} span={8}>
                          <Form.Item
                            name={["cargo", "id"]}
                            label={campoColaborador.etiqueta}
                            rules={
                              campoColaborador.campoObligatorio === true
                                ? [
                                    {
                                      required: true,
                                      message: "Seleccione Cargo",
                                    },
                                  ]
                                : []
                            }
                          >
                            <Select
                              onChange={handleOnChangeCargo}
                              showSearch
                              optionFilterProp="children"
                            >
                              {cargos.length > 0 &&
                                cargos.map((cargo) => {
                                  return (
                                    <Option key={cargo.id} value={cargo.id}>
                                      {cargo.nombre}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                          <Form.Item name={["cargo", "nombre"]} noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                        </Col>
                      );
                    }
                  })}
                  <Col span={12}>
                  <Form.Item label={" "}>
                    <Button type="primary" onClick={onClickFiltrar} ghost>
                      <FilterOutlined />Filtrar
                  </Button>
                  </Form.Item>
                  
                  </Col>
              </Row>
            </div>
          )}

          {/* Tabla */}
          <Table
  rowKey={(value) => value.id}
  columns={columns}
  dataSource={colaboradoresAsignacion}
  pagination={{
    current: paginaActual,
    pageSize: 2, // Cantidad de registros por página
    total: totalRegistros,
    showTotal: (total) => `Total ${total} registros`,
    onChange: onCambiarPaginacion, // Vincula la función de cambio de página
  }}
  bordered
  style={{ marginTop: "10px" }}
/>
        </Form>
      </Container>
    </JotaiProvider>
  );
};
export default AsignacionMasivaCrearPage;
