import { useAtom } from "jotai";
import { openDrawerActualizarColaboradoresAtom } from "../ColaboradorListarStore";

const useDrawerActualizarColaboradores = () => {
    const [open, setOpen] = useAtom(openDrawerActualizarColaboradoresAtom);

    const abrirDrawer = () => {
        setOpen(true);
    }

    const cerrarDrawer = () => {
        setOpen(false);
    }

    return {
        open,
        abrirDrawer,
        cerrarDrawer
    };

};

export default useDrawerActualizarColaboradores;