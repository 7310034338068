import React, { useEffect, useRef, useState } from 'react';
import { Button, notification, Select, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router';
import { Provider as JotaiProvider, useAtom } from "jotai";
import styled from '@emotion/styled';
import { DeleteOutlined, FormOutlined, HighlightOutlined, UsergroupAddOutlined } from "@ant-design/icons"
import "./css/ConfigurarFlujoFirmaPage.css"
import { useFirmantes, useVisor } from './hooks';
import { TipoControl } from 'enums';
import { Document, Page } from 'react-pdf';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { componentesAtom, componentesSeleccionadosAtom } from './store/ConfigurarFlujoFirmaStore';
import produce from 'immer';
import Moveable from 'react-moveable';
import Selecto from 'react-selecto';
import { FlujoFirmaService } from 'services/notificacion';
import { useHistory } from 'react-router-dom';
import { CategoriaDocumento } from 'enums/CategoriaDocumento';

const ConfigurarFlujoFirmaPage = () => {
    return (
        <JotaiProvider>
            <DndProvider backend={HTML5Backend}>
                <div style={{ border: "1px solid #ccc", height: "calc(100vh - 180px)", display: "flex" }}>
                    <PanelConfiguracion />
                    <VisorDocumento />
                </div>
                <Botones />
            </DndProvider>
        </JotaiProvider >
    );
};

const PanelConfiguracion = () => {
    return (
        <div style={{ width: "25%", backgroundColor: "white", height: "100%" }}>
            <Tabs
                defaultActiveKey="1"
                tabPosition='left'
                size='small'
                tabBarGutter={0}
                style={{ height: "100%" }}
                items={[
                    {
                        key: 'controles',
                        label: <UsergroupAddOutlined style={{ fontSize: "18px", marginRight: "0px" }} />,
                        children: <ControlesTab />,
                        style: { paddingLeft: "12px", paddingRight: "12px" }
                    },
                    // {
                    //     key: 'propiedades',
                    //     label: <FormOutlined style={{ fontSize: "16px", marginRight: "0px" }} />,
                    //     children: `Content of Tab Pane 2`,
                    // }
                ]}
            />
        </div>
    )
}

const ControlesTab = () => {

    const { firmantes, firmanteSeleccionado, seleccionarFirmante, fetchFirmantes } = useFirmantes();

    useEffect(() => {
        fetchFirmantes();
    }, [])

    return (
        <div>
            <div style={{ marginTop: "10px" }}>Firmantes</div>
            <Select
                bordered={false}
                style={{
                    width: '100%',
                    borderBottom: "1px solid #ccc"
                }}
                value={firmanteSeleccionado?.id}
                options={firmantes}
                onSelect={seleccionarFirmante}
            />
            <div style={{ marginTop: "15px" }}>
                <Control
                    icono={<HighlightOutlined />}
                    tipo={TipoControl.SIGNATURE}
                    nombre="Firma"
                />
            </div>
        </div>
    )
}

const Control = ({ icono, tipo, nombre, posicion }) => {

    const { firmanteSeleccionado } = useFirmantes();

    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: TipoControl.EFORM_PDF,
            item: { name: nombre, tipo: tipo },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [nombre]
    );

    return (
        <div
            ref={dragRef}
            style={{
                cursor: "move",
                background: firmanteSeleccionado?.color,
                color: "#fff",
                borderRadius: "0.3em",
                fontSize: ".8em",
                padding: "5px 5px 5px 8px",
                marginTop: "0.2rem",
                opacity: opacity,
                width: 200,
                height: 30
            }}
        >
            {icono} {nombre}
        </div>
    );
};

const VisorDocumento = () => {
    let { flujoFirmaId } = useParams();
    const { urlDocumento, totalPaginas, componentes, fetchComponentes, fetchUrlDocumento, onLoadSuccess } = useVisor();

    useEffect(() => {
        fetchUrlDocumento();
        fetchComponentes();
    }, []);

    useEffect(() => {
        FlujoFirmaService.guardarComponentes(flujoFirmaId, { componentes });
    }, [componentes]);


    return (
        <div style={{
            flex: "1 1 75%",
            height: "100%", // Asegura que ocupe toda la altura de la ventana
            width: "100%", // Asegura que ocupe toda la anchura disponible
        }}>
            <div
                id="container"
                className='container-items'
                style={{
                    // border: "1px solid red",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    overflow: "auto", // Habilita el desplazamiento si es necesario
                    display: "flex", // Añadido para centrar contenido
                    justifyContent: "center", // Centrar horizontalmente
                }}>

                <div className="visor-formulario-flujo-firma" style={{ height: "100%" }}>
                    <Document file={urlDocumento} onLoadSuccess={onLoadSuccess} loading={null}>
                        {Array.from(new Array(totalPaginas), (_, index) => (
                            <PaginaPdf key={`page_${index + 1}`} numeroPagina={index + 1} />
                        ))}
                    </Document>
                </div>
            </div>
        </div>
    )
}

const PaginaPdf = ({ numeroPagina }) => {
    const { totalPaginas, zoom, getKeyComponente } = useVisor();
    const [componentes, setComponentes] = useAtom(componentesAtom);
    const [componentesSeleccionados, setComponentesSeleccionados] = useAtom(componentesSeleccionadosAtom);
    const paginaRef = useRef();
    const [targets, setTargets] = useState([]);
    const moveableRef = useRef();
    const selectoRef = useRef();
    const { firmanteSeleccionado } = useFirmantes();

    const eliminarComponentes = () => {
        setComponentes(
            produce((draft) => {
                componentesSeleccionados.forEach((id) => {
                    const index = draft.findIndex((c) => c.key === id);
                    if (index !== -1) {
                        draft.splice(index, 1);
                    }
                });
            })
        );
        setComponentesSeleccionados([]);
    }

    const [, dropRef] = useDrop(() => ({
        accept: TipoControl.EFORM_PDF,
        drop: (item, monitor) => {
            const offset = monitor.getSourceClientOffset();
            if (offset && paginaRef.current) {
                const paginPdfBound = paginaRef.current.getBoundingClientRect();

                let relativeLeft = (offset.x - paginPdfBound.left) / zoom;
                let relativeTop = (offset.y - paginPdfBound.top) / zoom;

                relativeLeft = Math.max(relativeLeft, 0);
                relativeTop = Math.max(relativeTop, 0);

                setComponentes(
                    produce((draft) => {
                        const propiedadesAdicionales = {};
                        if (item.tipo === TipoControl.SIGNATURE) {
                            propiedadesAdicionales.signatureType = "IMAGEN_Y_DESCRIPCION";
                            propiedadesAdicionales.signaturePerson = "PERSONALIZADO";
                        }
                        draft.push({
                            key: getKeyComponente(item.tipo),
                            label: getKeyComponente(item.tipo),
                            type: item.tipo,
                            required: true,
                            overlay: {
                                page: numeroPagina,
                                left: relativeLeft,
                                top: relativeTop,
                                width: 200,
                                height: 30,
                            },
                            color: firmanteSeleccionado.color,
                            colaboradorId: firmanteSeleccionado.id,
                            ...propiedadesAdicionales
                        });
                    })
                );

            }

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }), [componentes, firmanteSeleccionado]);

    const SeparadorPaginas = () => {
        return (
            <div style={{ width: "100%", position: "relative" }}>
                <div style={{ height: "2em", backgroundColor: "#f4f4f4" }}></div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <SeparadorPaginas />
            <div
                ref={(elem) => {
                    paginaRef.current = elem;
                    dropRef(elem);
                }}
                style={{
                    position: "relative",
                    pointerEvents: "auto",
                    backgroundColor: "white",
                }}
                className={`container-visor${numeroPagina}`}
            >
                <Page pageNumber={numeroPagina} loading={null} scale={zoom} />

                <Moveable
                    ref={moveableRef}
                    target={targets}
                    ables={[Editable]}
                    props={{
                        editable: true,
                        eliminarComponentes: eliminarComponentes
                    }}
                    draggable={true}
                    throttleDrag={1}
                    edgeDraggable={false}
                    startDragRotate={0}
                    throttleDragRotate={0}
                    resizable={true}
                    keepRatio={false}
                    snappable={true}
                    bounds={{ "left": 0, "top": 0, "right": 0, "bottom": 0, "position": "css" }}
                    edge={[]}
                    onClickGroup={e => {
                        selectoRef.current.clickTarget(e.inputEvent, e.inputTarget);
                    }}
                    onDrag={e => {
                        const [x, y] = e.beforeTranslate;
                        e.target.style.transform = `translate(${x}px, ${y}px)`;
                    }}
                    onDragGroup={e => {
                        e.events.forEach(ev => {
                            const [x, y] = ev.beforeTranslate;
                            ev.target.style.transform = `translate(${x}px, ${y}px)`;
                        });
                    }}
                    onDragEnd={({ target, isDrag, clientX, clientY }) => {
                        const key = target.getAttribute('data-id');

                        const matrix = new DOMMatrix(target.style.transform);

                        // Obtener las posiciones x e y
                        const x = matrix.m41; // Posición horizontal
                        const y = matrix.m42; // Posición vertical

                        setComponentes(
                            produce((draft) => {
                                const componenteEncontrado = draft.find((c) => c.key === key);
                                if (componenteEncontrado) {
                                    componenteEncontrado.overlay.left = x;
                                    componenteEncontrado.overlay.top = y;
                                }
                            })
                        );

                    }}
                    onResize={e => {
                        e.target.style.width = `${e.width}px`;
                        e.target.style.height = `${e.height}px`;
                        e.target.style.transform = e.drag.transform;
                    }}
                    onResizeEnd={({ target }) => {
                        const key = target.getAttribute('data-id'); // Obtener el atributo data-id
                        const width = parseFloat(target.style.width);  // Obtener el ancho desde el atributo style
                        const height = parseFloat(target.style.height); // Obtener el alto desde el atributo style

                        setComponentes(
                            produce((draft) => {
                                const componenteEncontrado = draft.find((c) => c.key === key);
                                if (componenteEncontrado) {
                                    componenteEncontrado.overlay.width = width;
                                    componenteEncontrado.overlay.height = height;
                                }
                            })
                        );

                    }}
                />

                <Selecto
                    ref={selectoRef}
                    dragContainer={`.container-visor${numeroPagina}`}
                    selectableTargets={[".area-seleccionable .componente"]}
                    hitRate={0}
                    selectByClick={true}
                    selectFromInside={false}
                    toggleContinueSelect={["shift"]}
                    ratio={0}
                    onDragStart={e => {
                        const moveable = moveableRef.current;
                        const target = e.inputEvent.target;
                        if (
                            moveable.isMoveableElement(target)
                            || targets.some(t => t === target || t.contains(target))
                        ) {
                            e.stop();
                        }
                    }}
                    onSelectEnd={e => {
                        const moveable = moveableRef.current;
                        if (e.isDragStart) {
                            e.inputEvent.preventDefault();

                            moveable.waitToChangeTarget().then(() => {
                                moveable.dragStart(e.inputEvent);
                            });
                        }

                        setTargets(e.selected);
                    }}
                    onSelect={e => {
                        if (e.added && e.added.length > 0) {
                            let ids = [];
                            e.added.forEach(el => {
                                // el.classList.add("selected");
                                ids.push(el.getAttribute('data-id'));
                                setComponentesSeleccionados(ids);
                            });
                        }
                    }}
                >

                </Selecto>


                <div
                    className='componentes area-seleccionable'
                    style={{
                        position: "absolute", // Absoluto para ajustarse al contenedor
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        pointerEvents: "auto", // Deshabilita la interacción con el clic
                        zoom: zoom,
                    }}>
                    {componentes?.length > 0 &&
                        componentes
                            .filter((componente) => componente.overlay.page === numeroPagina)
                            .map((componente, index) => {
                                return <Componente key={componente.key} componente={componente} />;
                            })}
                </div>
            </div>
            {numeroPagina === totalPaginas
                && <SeparadorPaginas />}
        </React.Fragment>
    );
};

const Editable = {
    name: "editable",
    props: [],
    events: [],
    render(moveable, React) {
        const rect = moveable.getRect();
        const { pos2 } = moveable.state;
        const { eliminarComponentes } = moveable.props;

        const EditableViewer = moveable.useCSS("div", `
        {
            position: absolute;
            left: 0px;
            top: 0px;
            will-change: transform;
            transform-origin: 0px 0px;
        }
        .custom-button {
            // width: 24px;
            height: 24px;
            //margin-bottom: 4px;
            background: #4af;
            border-radius: 4px;
            appearance: none;
            border: 0;
            color: white;
            font-weight: bold;
        }
        .delete-button {
            height: 24px;
            background: #4af;
        }
            `);
        return <EditableViewer key={"editable-viewer"} className={"moveable-editable"} style={{
            transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(10px)`,
        }}>
            <Tooltip title="Eliminar Control">
                <Button type="primary" danger style={{ padding: "0px 8px" }} onClick={eliminarComponentes}>
                    <DeleteOutlined />
                </Button>
            </Tooltip>
        </EditableViewer>;
    },
};

const Componente = ({ componente }) => {
    return (
        <>
            <div
                data-id={componente.key}
                className="componente"
                style={{
                    border: `1px solid ${componente.color}`,
                    position: "absolute",
                    width: `${componente.overlay.width}px`,
                    height: `${componente.overlay.height}px`,
                    transform: `translate(${componente.overlay.left}px, ${componente.overlay.top}px)`,
                    pointerEvents: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: hexToRgba(componente.color, 0.15),
                    color: "#333",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                    "--color": "#4af"
                }}>
                {getNombre(componente.type)}
            </div >
        </>
    );
};

const getNombre = (type) => {
    if (type === TipoControl.SIGNATURE) {
        return "Firma";
    }
    return type;
}

const Botones = () => {

    const history = useHistory();
    let { flujoFirmaId } = useParams();
    const { componentes } = useVisor();
    const { firmantes } = useFirmantes();
    const [loading, setLoading] = useState(false);


    const onClickBtnNotificar = async () => {
        setLoading(true);

        let firmantesTienenFirma = true;
        for (const firmante of firmantes) {
            // Buscar si existe un componente de tipo 'signature' con el colaboradorId igual al value del firmante
            const tieneFirma = componentes.some(
                (componente) => componente.type === 'signature' && componente.colaboradorId === firmante.value
            );

            // Si no tiene firma, retornar el mensaje
            if (!tieneFirma) {
                firmantesTienenFirma = false;
                setLoading(false);
                notification.error({
                    message: "Alerta!",
                    description: "Todos los firmantes deben tener su firma"
                })
            }
        }

        if (firmantesTienenFirma) {
            const envioId = await FlujoFirmaService.notificar(flujoFirmaId);
            history.push(`/notificacion/seguimiento/detalle/${CategoriaDocumento.FLUJO_FIRMA.nombre}/${envioId}`);
        }
    }

    return (
        <FooterContainer>
            <Button size='large' type='primary' onClick={onClickBtnNotificar} loading={loading}>
                Notificar
            </Button>
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
    background-color: #fff;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 20px
`

const hexToRgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((c) => parseInt(c, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default ConfigurarFlujoFirmaPage;