import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { PlusOutlined, FileExcelOutlined, CaretDownOutlined} from "@ant-design/icons";
import { Button, Space, notification, message, Dropdown, Table } from "antd";
import { useHistory } from "react-router-dom";

const AsignacionTurnoPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Bandeja de Asignacion de Turnos" botonExtra={<Botones />}>
        <ListarAsignaciones />
      </Container>
    </JotaiProvider>
  );
};

const Botones = () => {
  let history=useHistory();
  const getItemsBotonAsignaciones = () => {
		const items = [];
		items.push({
			label: "Masiva",
			key: "asignacion-turno-masiva",
			icon: <FileExcelOutlined style={{ fontSize: "18px" }} />,
		},
    {
      label: "Semanal",
			key: "asignacion-turno-semanal",
			icon: <FileExcelOutlined style={{ fontSize: "18px" }} />,
    });

		return items;
	}

  const onClickBtnAsignaciones = (e) => {
		if (e.key === "asignacion-turno-masiva") {
      history.push("/asistencia/asignacion-turnos/masiva/crear")
		} else if (e.key === "asignacion-turno-semanal") {
			history.push("/asistencia/asignacion-turnos/semanal/crear")
		}
	};

  return (
    <Dropdown.Button
      menu={{
        items: getItemsBotonAsignaciones(),
        onClick: onClickBtnAsignaciones,
      }}
      //onClick={handleOnClickCargaMasivaColaborador}
      icon={<CaretDownOutlined />}
      trigger={["click"]}
      arrow
    >
      Tipos de Asignaciones
    </Dropdown.Button>
  );
};
const ListarAsignaciones = () => {
  const [loading, setLoading] = useState(true);
  const [asignaciones, setAsignaciones] = useState([]);
  // useEffect(() => {
  //   const inicializar = () => {
  //     // if (open) {
  //     fetchTurnos();
  //     // setFormInstance(form);
  //     // }
  //   };
  //   inicializar();
  // }, []);

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      align: "center",
    },
    {
      title: "Personas",
      dataIndex: "persona",
      align: "center",
    },
    {
      title: "Horario",
      dataIndex: "horario",
      align: "center",
    },
    {
      title: "Horario refrigerio",
      dataIndex: "horarioRefrigerio",
      align: "center",
    },
    {
      title: "Tolerancia",
      dataIndex: "tolerancia",
      align: "center",
    },
    // {
    //     title: 'Acciones',
    //     align: 'center',
    //     width: '10%',
    //     render: (fila) => <MenuAcciones requisito={fila} />
    // },
  ];

  return (
    <Table
      rowKey={(asignaciones) => asignaciones.id}
      dataSource={asignaciones}
      columns={columnas}
    />
  );
};
export default AsignacionTurnoPage;
