import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Provider as JotaiProvider } from "jotai";
import { Layout, Form, Input, Checkbox, Table, TimePicker, Button, 
  Space, Modal, notification} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { Container } from "components/Container";
import { useHistory, useParams } from "react-router-dom";
import { TurnoService } from "services/asistencias/TurnoService";
import { LoginService } from "services/LoginService";
// import { turnoCreadoAtom
// } from "../store/store";

const { Header, Content } = Layout;

const ConfiguracionSemanalPage = () => {
    let { turnoId } = useParams();
    const history = useHistory();
    const [form] = Form.useForm();
    const [totalHours, setTotalHours] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [replicateTime, setReplicateTime] = useState({ from: null, to: null });
    const [showRefrigerio, setShowRefrigerio] = useState(false);
    const [turno, setTurno] = useState({});
    const [loading, setLoading] = useState(false);

    const days = [
      { key: "1", name: "Lunes" },
      { key: "2", name: "Martes" },
      { key: "3", name: "Miércoles" },
      { key: "4", name: "Jueves" },
      { key: "5", name: "Viernes" },
      { key: "6", name: "Sábado" },
      { key: "7", name: "Domingo" },
    ];

    useEffect(() => {
        const fetch = async () => {
          try {
            const _turno = await TurnoService.buscarPorId(
              turnoId
            );
            setTurno(_turno);
            console.log("_turno", _turno);

            form.setFieldsValue({
              nombre: _turno?.nombre,   
            });
            console.log("turnoCreado",_turno?.nombre);
          } catch (error) {
            console.error(error);
          }
        }
        fetch();
    }, []);

    const initialData = days.map(({ key, name }) => ({
      key,
      day: name,
      active: false,
      startShift: null,
      endShift: null,
      startBreak: null,
      endBreak: null,
    }));

    const [dataSource, setDataSource] = useState(initialData);

    const columns = [
      {
        title: "Activo",
        dataIndex: "active",
        render: (_, record) => (
          <Checkbox
            checked={record.active}
            onChange={(e) => handleActiveChange(record.key, e.target.checked)}
          />
        ),
      },
      {
        title: "Día Trabajo",
        dataIndex: "day",
      },
      {
        title: "Inicio Jornada",
        dataIndex: "startShift",
        render: (_, record) => (
          <TimePicker
            value={record.startShift}
            onChange={(time) => handleTimeChange(record.key, "startShift", time)}
            format="HH:mm"
            disabled={!record.active}
          />
        ),
      },
      ...(showRefrigerio
        ? [
            {
              title: "Inicio de Refrigerio",
              dataIndex: "startBreak",
              render: (_, record) => (
                <TimePicker
                  value={record.startBreak}
                  onChange={(time) => handleTimeChange(record.key, "startBreak", time)}
                  format="HH:mm"
                  disabled={!record.active}
                />
              ),
            },
            {
              title: "Término de Refrigerio",
              dataIndex: "endBreak",
              render: (_, record) => (
                <TimePicker
                  value={record.endBreak}
                  onChange={(time) => handleTimeChange(record.key, "endBreak", time)}
                  format="HH:mm"
                  disabled={!record.active}
                />
              ),
            },
          ]
        : []),
      {
        title: "Término Jornada",
        dataIndex: "endShift",
        render: (_, record) => (
          <TimePicker
            value={record.endShift}
            onChange={(time) => handleTimeChange(record.key, "endShift", time)}
            format="HH:mm"
            disabled={!record.active}
          />
        ),
      },
    ];

    const handleActiveChange = (key, checked) => {
      const updatedDataSource = dataSource.map((row) =>
        row.key === key ? { ...row, active: checked } : row
      );
      setDataSource(updatedDataSource);
    };

    const handleTimeChange = (key, field, time) => {
      const updatedDataSource = dataSource.map((row) =>
        row.key === key ? { ...row, [field]: time } : row
      );
      setDataSource(updatedDataSource);
      calculateTotalHours(updatedDataSource);
    };

    const calculateTotalHours = (data) => {
      let hours = 0;
      data.forEach((row) => {
        if (row.active && row.startShift && row.endShift) {
          const start = moment(row.startShift, "HH:mm");
          const end = moment(row.endShift, "HH:mm");
          hours += moment.duration(end.diff(start)).asHours();
        }
      });
      setTotalHours(hours.toFixed(2));
    };

    const handleClearAll = () => {
      setDataSource(initialData);
      setTotalHours(0);
    };

    const showReplicateModal = () => {
      setIsModalOpen(true);
    };

    const handleReplicate = () => {
      const updatedDataSource = dataSource.map((row) =>
        row.active
          ? {
              ...row,
              startShift: replicateTime.from,
              endShift: replicateTime.to,
            }
          : row
      );
      setDataSource(updatedDataSource);
      calculateTotalHours(updatedDataSource);
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const toggleRefrigerio= (e) => {
     const isChecked = e.target.checked;
      setShowRefrigerio(isChecked);
      if (isChecked) {
        form.setFieldsValue({ duracionRefrigerio: moment("00:00", "HH:mm") }); // Resetea el valor
      }
    };

    const onClickRetonar=()=>{
        history.push("/asistencia/turnos");
    }

    const handleSave = async () => {
      try {
        let usuarioModificacion = {
          nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
          numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
        }
        setLoading(true);
        const configuracion ={
          semanal: dataSource.map((row) => ({
            id: row.key, // 1
            dia: row.day, //"Lunes"
            activo: row.active,
            inicioJornada: row.startShift ? row.startShift.format("HH:mm") : null,
            inicioRefrigerio: row.startBreak ? row.startBreak.format("HH:mm") : null,
            terminoRefrigerio: row.endBreak ? row.endBreak.format("HH:mm") : null,
            terminoJornada: row.endShift ? row.endShift.format("HH:mm") : null,
          }))
        }

        console.log("configuracion::", configuracion);

        const turnoData = {
          id: turno.id,
          nombre: turno.nombre,
          tipo: turno.tipo,
          trabajaFestivos: form.getFieldValue("trabajaFestivos") || false,
          marcaRefrigerio: form.getFieldValue("marcaRefrigerio") || false,
          duracionRefrigerio: form.getFieldValue("duracionRefrigerio") ? form.getFieldValue("duracionRefrigerio").format("HH:mm") : null,
          tolerancia: parseInt(form.getFieldValue("tolerancia"), 10) || 0,
          horasTotales: parseFloat(totalHours) || 0,
          usuarioRegistro: turno.usuarioRegistro,
          usuarioModificacion: usuarioModificacion,
          estado: turno.estado,
          empresaId: turno.empresaId,
          configuracion: configuracion,
        };
        console.log("form::", turnoData);
  
        const respuesta = await TurnoService.actualizar(turnoData);
        
        notification.success({
          message: "Operación exitosa",
          description: "Se registró la configuración del turno exitosamente",
        });
        setLoading(false);
        history.push(`/asistencia/turnos`);

      } catch (error) {
        setLoading(false);
        console.error(error);
        notification.error({
          message: "Alerta! Ocurrio un error la configuración del turno",
        });
      }
    }

    return (
      <JotaiProvider>
          <Container titulo={<div><h5>Configuración de turno semanal</h5></div>} onClickRetornar={onClickRetonar}>
          <Content>
            <div style={{ display: "flex", gap: "16px", alignItems: "stretch" }}>
              <div style={{ width: "30%", background: "#fff", padding: "16px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0,0,0,0.3)" }}>
                <h3>Configuración</h3>
                <Form
                  form={form}
                  // onFinish={handleSave}
                  initialValues={{
                    trabajaFestivos: false,
                    marcaRefrigerio: false,
                    tolerancia: 0,
                    duracionRefrigerio: moment("00:00", "HH:mm"),
                  }}
                  layout="vertical"
                  style={{ height: "100%" }}
                >
                  <Form.Item label="Nombre del turno" name="nombre">
                    <Input readOnly/>
                  </Form.Item>
                  <Form.Item name="trabajaFestivos" valuePropName="checked">
                    <Checkbox>Trabaja Festivos</Checkbox>
                  </Form.Item>
                  <Form.Item name="marcaRefrigerio" valuePropName="checked">
                    <Checkbox onChange={toggleRefrigerio}>Marca Refrigerio</Checkbox>
                  </Form.Item>
                  {!showRefrigerio && (
                <Form.Item label="Duración Refrigerio" name="duracionRefrigerio">
                    <TimePicker
                    format="HH:mm"
                    defaultValue={moment("00:00", "HH:mm")} // Valor por defecto: 0 hora
                    />
                </Form.Item>
                  )}
                  <Form.Item label="Tolerancia" name="tolerancia">
                    <Input type="number" min={0} placeholder="0" />
                  </Form.Item>
                  <div>
                    <strong>Horas totales del turno:</strong> {totalHours} horas
                  </div>
                </Form>
              </div>
              <div style={{ width: "70%", background: "#fff", padding: "16px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0,0,0,0.3)", display: "flex", flexDirection: "column" }}>
                <h3>Distribución de turno</h3>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px", marginBottom: "16px" }}>
                  <Button type="default" onClick={showReplicateModal}>
                    Replicar horario
                  </Button>
                  <Button type="default" icon={<DeleteOutlined />} onClick={handleClearAll}>
                    Limpiar todo
                  </Button>
                  <Button type="primary" icon={<PlusOutlined />} onClick={handleSave} loading={loading}>
                    Guardar
                  </Button>
                </div>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  bordered
                  rowClassName={(record) =>
                    record.active ? "ant-table-row-selected" : ""
                  }
                  style={{ flex: 1 }}
                />
              </div>
            </div>
            <Modal
              title="Replicar horario"
              open={isModalOpen}
              onCancel={handleCancel}
              onOk={handleReplicate}
              okText="Aceptar"
              cancelText="Cancelar"
            >
              <Form layout="vertical">
                <Form.Item label="Desde">
                  <TimePicker
                    value={replicateTime.from}
                    onChange={(time) =>
                      setReplicateTime((prev) => ({ ...prev, from: time }))
                    }
                    format="HH:mm"
                  />
                </Form.Item>
                <Form.Item label="Hasta">
                  <TimePicker
                    value={replicateTime.to}
                    onChange={(time) =>
                      setReplicateTime((prev) => ({ ...prev, to: time }))
                    }
                    format="HH:mm"
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Content>
        </Container>
      </JotaiProvider>
    );
  };

export default ConfiguracionSemanalPage;